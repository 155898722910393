import { debounce } from "lodash";
import Const from "../const/const";
import { useLightbox } from '../context/lightboxContext';
import { useEffect, useState } from "react";
import classNames from "classnames";

const CeInlineButton = ({ languageKey, onClick }) => {

    const { lightboxEnabled } = useLightbox();

    const [pauseAnimation, setPauseAnimation] = useState(false);

    const innerOnClick = (e) => {
        console.log(e);
        onClick();
    }

    useEffect(() => {
        if (lightboxEnabled) {
            setPauseAnimation(true);
        } else {
            setPauseAnimation(false);
        }
    }, [lightboxEnabled]);

    const debouncedOnClick = debounce(innerOnClick, Const.debounceValue);

    return (
        <div className="ce-inline-button__container ce-inline-button__animation" onClick={debouncedOnClick} onTouchEnd={debouncedOnClick}>
            <div className="ce-inline-button__top-left ce-inline-button__corner"></div>
            <div className="ce-inline-button__top-right ce-inline-button__corner"></div>
            <div className="ce-inline-button__bottom-left ce-inline-button__corner"></div>
            <div className="ce-inline-button__bottom-right ce-inline-button__corner"></div>
            <div className={
                classNames(
                    "ce-inline-button__background-container ce-inline-button__background_animation",
                    {
                        "ce-inline-button__background_animation-pause": pauseAnimation
                    }
                )}>
                
            </div>
            <div className="ce-inline-button__text-container">
                {languageKey}
            </div>
        </div>
        
    );
};

export default CeInlineButton;