import { useEffect, useState } from "react"
import pad from "pad";
import CircularSeekBar from '../../components/circular-seekbar';
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Lottie from "react-lottie";

import * as marsDL1 from '../../lottie/mars-rover/Mars_IndicLines_AN1.json';
import * as marsDL2 from '../../lottie/mars-rover/Mars_IndicLines_AN2.json';
import * as marsDL3 from '../../lottie/mars-rover/Mars_IndicLines_AN3.json';
import marsPageData from '../../json/mars-rover-data-point.json';
import classNames from "classnames";
import RoverButton from "../../components/rover-button";
import ScrollableViewWithFader from "../../components/scrollable-view-with-fader";

import lightBoxImg1 from '../../img/mars-rover/photo/1.jpg';
import lightBoxImg2 from '../../img/mars-rover/photo/2.jpg';
import lightBoxImg3 from '../../img/mars-rover/photo/3.jpg';
import lightBoxImg4 from '../../img/mars-rover/photo/4.jpg';
import lightBoxImg5 from '../../img/mars-rover/photo/5.jpg';
import lightBoxImg6 from '../../img/mars-rover/photo/6.jpg';
import lightBoxImg7 from '../../img/mars-rover/photo/7.jpg';
import lightBoxImg8 from '../../img/mars-rover/photo/8.jpg';
import lightBoxImg9 from '../../img/mars-rover/photo/9.mp4';
import lightBoxImg10 from '../../img/mars-rover/photo/10.jpg';
import lightBoxImg11 from '../../img/mars-rover/photo/11.jpg';
import lightBoxImg12 from '../../img/mars-rover/photo/12.jpg';
import lightBoxImg13 from '../../img/mars-rover/photo/13.jpg';
import { throttle } from "lodash";

const photos = {
  "1": lightBoxImg1,
  "2": lightBoxImg2,
  "3": lightBoxImg3,
  "4": lightBoxImg4,
  "5": lightBoxImg5,
  "6": lightBoxImg6,
  "7": lightBoxImg7,
  "8": lightBoxImg8,
  "9": lightBoxImg9,
  "10": lightBoxImg10,
  "11": lightBoxImg11,
  "12": lightBoxImg12,
  "13": lightBoxImg13
};

const lottieWidth = 2160;
const lottieHeight = 1500;

const MarsRover = () => {
  const { i18n } = useTranslation();

  const [intVal, setIntVal] = useState(1);
  const [showDotAndLine, setShowDotAndLine] = useState(true);
  const [showLightBox, setShowLightBox] = useState(false);
  const [lightBoxContent, setLightBoxContent] = useState(null);

  const tSetShowDotAndLine = throttle(setShowDotAndLine, 500);

  const LightBoxContent = ({ content }) => {
    const { i18n } = useTranslation();
    const isZh = (i18n.language === 'zh');

    useEffect(() => {
      document.querySelector('.customscroll').scrollTop = 0;
      // eslint-disable-next-line
    }, [lightBoxContent]);

    return (
      <div className={
        classNames(
          `mars-rover__lightbox-type-${content['type']}`
        )
      }>
        {
          (photos[content['id']]) && 
          (content['id'] === "9") ? <video muted={true} className="mars-rover__lightbox-video" autoPlay={true} loop={true} preload="auto">
            <source src={photos[content['id']]} />
          </video> :<img className="mars-rover__lightbox-image" src={photos[content['id']]} alt='' />
        }
        <div className="mars-rover__lightbox-photo-caption">{ (isZh) ? content['caption_zh'] : content['caption_en'] }</div>
        <div className="mars-rover__lightbox-title-row">
          <div className="mars-rover__lightbox-title-left-pane">
            <div className="mars-rover__lightbox-title">{ (isZh) ? content['title_zh'] : content['title_en'] }</div>
            <div className="mars-rover__lightbox-desc-container">
              <div className="mars-rover__lightbox-pre-dash"></div>
              <div className="mars-rover__lightbox-desc">{ (isZh) ? content['desc_zh'] : content['desc_en'] }</div>
            </div>
          </div>
          <div className="mars-rover__lightbox-title-type-pane">
              <div className="mars-rover__lightbox-class-dot"></div>
              <div>{
                (content['type'] === "sp") ? t('marsRover.science-payload') : t('marsRover.general')
              }</div>
          </div>
        </div>
        <div className={
          classNames(
            "mars-rover__lightbox-bullets-container",
            {
              "mars-rover__lightbox-bullets-container-zh": isZh,
              "mars-rover__lightbox-bullets-container-en": !isZh
            }
          )
        }>
          <ul>
            {
              (((isZh)?content['bullets_zh']:content['bullets_en']).map((v) => {
                return <li>{ (v.indexOf('C11H24') > 0)? 
                  ((i18n.language === 'en') ? 
                  <>The heat-collecting device was filled with a chemical called<br />n-Undecane (C<sub>11</sub>H<sub>24</sub>). During the day, the substance absorbed heat and melted. When the temperature dropped at night, the substance solidified and released the stored thermal energy, which helped keep the rover warm.</>:
                  <>集熱器內部填充物是一種化學物料——正十一烷 (C<sub>11</sub>H<sub>24</sub>)，它在日間吸熱融化，夜間凝固放熱，為祝融號保溫。</>
              ): v }</li>
              }))
            }
          </ul>
        </div>
      </div>
    );
  }
  
  return (
    <>
      <div className="mars-rover showAnimation">
        <div className={
          classNames(
            "mars-rover__rover-title",
            {
              "mars-rover__rover-title-en": (i18n.language === 'en')
            }
          )
        }></div>
        <div className="mars-rover__shadow-bg"></div>
        {(!showLightBox &&
          <>
            <div className={
              classNames(
                "moon-rover__rover-instruction",
                {
                  "moon-rover__rover-instruction-en": (i18n.language === 'en')
                }
              )
            }>
              {t('moonRover.instruction')}
              <RoverButton className={"moon-rover__rover-button-inline"} />
              {t('moonRover.instruction2')}
            </div>
          </>
        )}
        <div className={"mars-rover__rover-img-seq"}>
          <img src={`${process.env.PUBLIC_URL}/mars-rover/zhroung.${pad(4, intVal, '0')}.png`} alt="" />
        </div>
        <div className="mars-rover__seek-bar">
          <CircularSeekBar onChange={(value) => {
            var newVal = parseInt(value) + 1;
            setIntVal(newVal);
            setShowDotAndLine(false);
          }} onSettled={(_) => {
            tSetShowDotAndLine(true);
          }} />
        </div>
      </div>
      {(intVal !== 0 && showDotAndLine) ? <DottedLineSceneContainer index={intVal} /> : <></>}
      {(intVal !== 0 && showDotAndLine) ? <LottieDotButtonContainer index={intVal} onClick={(index) => {
        console.log(index);
        setLightBoxContent(marsPageData['points'][index.toString()]);
        setShowLightBox(true);
      }} /> : <></>}
      <div className={
        classNames(
          "mars-rover__lightbox-outer",
          {
            "visible": showLightBox
          }
        )}>
          <div className={
              classNames(
                "mars-rover__lightbox-container",
                "mars-rover__lightbox-share-prop",
                {
                  "mars-rover__lightbox-container-en": (i18n.language === 'en'),
                  "mars-rover__lightbox-container-zh": (i18n.language === 'zh')
                }
              )
            }>
            <div className="mars-rover__lightbox-inner-dark-container mars-rover__lightbox-share-prop"></div>
            <div className="mars-rover__lightbox-inner-container mars-rover__lightbox-share-prop"></div>
            <div className="mars-rover__lightbox-border-container mars-rover__lightbox-share-prop"></div>
            <ScrollableViewWithFader className="mars-rover__lightbox-scroll-view">
                {(lightBoxContent) && <LightBoxContent content={lightBoxContent} />}
            </ScrollableViewWithFader>
            <div className="mars-rover__lightbox-close-btn" onClick={(e) => {
              e.preventDefault();
              setShowLightBox(false);
            }}  onTouchEnd={(e) => {
              e.preventDefault();
              setShowLightBox(false);
            }}></div>
          </div>
          
      </div>
    </>
  )
}

const LottieDotButtonContainer = (({ index, onClick }) => {
  const arr = marsPageData['dotted'][index.toString()];
  return (
    <div className="mars-rover__button-lottie-container">
      {arr && arr.map((v) => <div className={classNames(
        "mars-rover__rover-button-container",
        `mars-rover__rover-button-container-id-${v['pointId']}`
      )} key={`${index}-${v['pointId']}`} 
      style={{
        top: `${v['y']}px`,
        left: `${v['x']}px`
      }}
      onClick={() => {
        onClick(v['pointId'])
      }}
      onTouchEnd={() => {
        onClick(v['pointId'])
      }} >
        <RoverButton className={"mars-rover__rover-button"} />
      </div>)}
    </div>
  )
});

const DottedLineSceneContainer = (({index}) => {
  return (
    <div className={
      classNames(
        "mars-rover__dotted-line-lottie-container", 
        `mars-rover__dotted-line-lottie-container-scene-${index}`
      )
    }>
        <DottedLineScene index={index} />
    </div>
  )
});

const DottedLineScene = (({index}) => {
  return (
    <div className={classNames(`mars-rover__dotted-line-scene-${index}`)}>
      <Lottie
        options={getLottieOption({index})}
        width={lottieWidth}
        height={lottieHeight}
        />
    </div>
  );
});


function getLottieOption({index}){
  return {
    loop: false,
    autoplay: true,
    animationData: getLottieJSON({index}),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
}

function getLottieJSON({index}) {
  switch(index) {
    case 1:
    return marsDL1; 
    case 2:
    return marsDL2; 
    case 3:
    return marsDL3; 
    default:
    return null;
  }
}

export default MarsRover
