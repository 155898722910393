import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import closeBtn from '../../img/mars-mission-intro/close-btn.png';
import ce1InlineImg from '../../img/moon-mission-intro/ce1-inline-img.jpg';
import ce1InlineEnImg from '../../img/moon-mission-intro/ce1-inline-img-en.jpg';
import ce2InlineImg from '../../img/moon-mission-intro/ce2-inline-img.jpg';
import ce2InlineEnImg from '../../img/moon-mission-intro/ce2-inline-img-en.jpg';
import ce3InlineImg from '../../img/moon-mission-intro/ce3-inline-img.jpg';
import ce3InlineEnImg from '../../img/moon-mission-intro/ce3-inline-img-en.jpg';
import ce3InlineImg2 from '../../img/moon-mission-intro/ce3-inline-img2.jpg';
import ce4InlineEnImg from '../../img/moon-mission-intro/ce4-inline-img-en.jpg';
import ce4InlineImg from '../../img/moon-mission-intro/ce4-inline-img.jpg';
import ce6InlineImg from '../../img/moon-mission-intro/ce6-inline-img.jpg';
import ce6InlineImgEn from '../../img/moon-mission-intro/ce6-inline-img-en.jpg';
import ce6InlineImg2 from '../../img/moon-mission-intro/ce6-inline-img2.jpg';
import CeInlineButton from '../../components/ce-inline-button';
import Lottie from 'react-lottie';

import img20071 from '../../img/moon-mission-intro/img-2007-1.jpg';
import img20072 from '../../img/moon-mission-intro/img-2007-2.jpg';
import img20101 from '../../img/moon-mission-intro/img-2010-1.jpg';
import img20102 from '../../img/moon-mission-intro/img-2010-2.jpg';
import img20131 from '../../img/moon-mission-intro/img-2013-1.jpg';
import img20131en from '../../img/moon-mission-intro/img-2013-1-en.jpg';
import img20141 from '../../img/moon-mission-intro/img-2014-1.jpg';
import img20142 from '../../img/moon-mission-intro/img-2014-2.jpg';
import img20143 from '../../img/moon-mission-intro/img-2014-3.jpg';
import img20181 from '../../img/moon-mission-intro/img-2018-1.jpg';
import img20181en from '../../img/moon-mission-intro/img-2018-1-en.jpg';
import img20182 from '../../img/moon-mission-intro/img-2018-2.jpg';
import img20182en from '../../img/moon-mission-intro/img-2018-2-en.jpg';
import img20183 from '../../img/moon-mission-intro/img-2018-3.jpg';
import img20184 from '../../img/moon-mission-intro/img-2018-4.jpg';
import video20184 from '../../video/MoonMission03_RoverLoop_1.webm';
import img20201 from '../../img/moon-mission-intro/img-2020-1.jpg';
import img20202 from '../../img/moon-mission-intro/img-2020-2.jpg';
import img20202En from '../../img/moon-mission-intro/img-2020-2-en.jpg';

import * as CE1AD from '../../lottie/moon-mission-intro/03.1.2_Moon_Mission_ChangE-1.json';
import mission2OverlayVideo from '../../video/mission-03-overlay-video/ChangE-2.webm';
import mission3OverlayVideo from '../../video/mission-03-overlay-video/03.1.2_Moon_Mission_ChangE-3.webm';
import mission4OverlayVideo from '../../video/mission-03-overlay-video/03.1.5_Moon_Mission_ChangE-4.webm';
import mission5OverlayVideo from '../../video/mission-03-overlay-video/03.1.6_Moon_Mission_ChangE-5.webm';
import mission6OverlayVideo from '../../video/mission-03-overlay-video/03.1.7_Moon_Mission_ChangE-6.webm';
import ScrollableViewWithFader from '../../components/scrollable-view-with-fader';
import { useLightbox } from '../../context/lightboxContext';
import { useTravel } from '../../context/travelContext';

import staticMoon1 from '../../img/moon-mission-intro/static-moon-1.png';
import staticMoon3 from '../../img/moon-mission-intro/static-moon-3.png';
import staticMoon4 from '../../img/moon-mission-intro/static-moon-4.png';
import staticMoon5 from '../../img/moon-mission-intro/static-moon-5.png';

import introLogoImg from '../../img/moon-mission-intro/intro-logo.jpg';

import * as ball1 from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_01-02.json';
import * as ball2 from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_03-04.json';
import * as ball3 from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_05-06.json';

const pageRouteName = '/moon-mission-intro';


const ball1Option = {
  loop: true,
  autoplay: true,
  animationData: ball1,
  rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
  }
}

const ball2Option = {
  loop: true,
  autoplay: true,
  animationData: ball2,
  rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
  }
}

const ball3Option = {
  loop: true,
  autoplay: true,
  animationData: ball3,
  rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
  }
}

const MoonMissionIntro = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [year, setYear] = useState(null);
  const [tag, setTag] = useState(null);
  const { enabledLightbox, disableLightbox, lightboxEnabled } = useLightbox();
  const { travelValue } = useTravel();
  const [ navigateToNext, setNavigateToNext ] = useState(false);

  const moonRover1Option = {
    loop: true,
    autoplay: true,
    animationData: CE1AD,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  useEffect(() => {
    if (travelValue !== pageRouteName) {
      setNavigateToNext(true);
    } else {
      setNavigateToNext(false);
    }
  }, [travelValue]);

  useEffect(() => {
    if (location.state && location.state.year) {
      setYear(location.state.year);
    }
  }, [location.state]);

  useEffect(() => {
    document.querySelector('.customscroll').scrollTop = 0;
  }, [year])

  const openLightbox = (tag) => {
    enabledLightbox();
    setTag(tag);
  }

  const closeLightbox = (e) => {
    disableLightbox();
    setTag(null);
  }

  const renderContent = () => {
    switch (year) {
      case 2007: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.2007.content"
              components={[
                <img src={(i18n.language === 'en')? ce1InlineEnImg : ce1InlineImg} alt="" />,
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2007.tag1.label')} onClick={() => openLightbox('2007_1')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2007.content2"
              components={[
                <img src={ce1InlineImg} alt="" />,
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2007.tag2.label')} onClick={() => openLightbox('2007_2')} />
            </div>
          </>
        )
      }
      case 2010: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.2010.content"
              components={[
                <img src={ce2InlineImg} alt="" />,
                <p />,
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2010.tag1.label')} onClick={() => openLightbox('2010_1')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2010.content2"
              components={[
                <img src={ce2InlineImg} alt="" />,
                <p />,
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2010.tag2.label')} onClick={() => openLightbox('2010_2')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2010.content3"
              components={[
                <img src={(i18n.language === "en")? ce2InlineEnImg : ce2InlineImg} alt="" />,
                <p />,
              ]}
            />
          </>
        )
      }
      case 2013: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.2013.content"
              components={[
                <img src={ (i18n.language === 'en') ? ce3InlineEnImg : ce3InlineImg} alt="" />,
                <p />,
              ]}
            />
            <Trans
              i18nKey="moonMissionIntro.2013.content2"
              components={[
                <img src={ce3InlineImg} alt="" />,
                <p />,
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2013.tag1.label')} onClick={() => openLightbox('2013_1')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2013.content3"
              components={[
                <img src={ce3InlineImg2} alt="" />,
                <p />,
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2013.tag2.label')} onClick={() => openLightbox('2013_2')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2013.content4"
              components={[
                <img src={ce3InlineImg2} alt="" />,
                <p />,
              ]}
            />
          </>
        )
      }
      case 2014: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.2014.content"
              components={[
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2014.tag1.label')} onClick={() => openLightbox('2014_1')} />
              <CeInlineButton languageKey={t('moonMissionIntro.2014.tag2.label')} onClick={() => openLightbox('2014_2')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2014.content1"
              components={[
                <img src={(i18n.language === 'en') ? ce4InlineEnImg : ce4InlineImg} alt="" />,
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2014.tag3.label')} onClick={() => openLightbox('2014_3')} />
            </div>
          </>
        )
      }
      case 2018: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.2018.content"
              components={[
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2018.tag1.label')} onClick={() => openLightbox('2018_1')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2018.content1"
              components={[
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2018.tag2.label')} onClick={() => openLightbox('2018_2')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2018.content2"
              components={[
                <p />
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2018.tag3.label')} onClick={() => openLightbox('2018_3')} />
              <CeInlineButton languageKey={t('moonMissionIntro.2018.tag4.label')} onClick={() => openLightbox('2018_4')} />
            </div>
          </>

        )
      }
      case 2020: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.2020.content"
              components={[
                <p />,
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2020.tag1.label')} onClick={() => openLightbox('2020_1')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2020.content1"
              components={[
                <p />,
                <img src={(i18n.language === 'en') ? ce6InlineImgEn : ce6InlineImg} alt="" />,
              ]}
            />
            <Trans
              i18nKey="moonMissionIntro.2020.content2"
              components={[
                <p />,
              ]}
            />
            <div className="moon-mission-intro__tags">
              <CeInlineButton languageKey={t('moonMissionIntro.2020.tag2.label')} onClick={() => openLightbox('2020_2')} />
            </div>
            <Trans
              i18nKey="moonMissionIntro.2020.content3"
              components={[
                <p />,
                <img src={ce6InlineImg2} alt="" />,
              ]}
            />
            <Trans
              i18nKey="moonMissionIntro.2020.content4"
              components={[
                <p />,
              ]}
            />
          </>
        )
      }
      default: {
        return (
          <>
            <Trans
              i18nKey="moonMissionIntro.content"
              components={[
                <p />,
              ]}
            />
            <Trans
              i18nKey="moonMissionIntro.li-title"
              components={[
                <p className='moon-mission-intro__li-title' />,
              ]}
            />
            <ul>
              <li>{t('moonMissionIntro.li1')}</li>
              <li>{t('moonMissionIntro.li2')}</li>
              <li>{t('moonMissionIntro.li3')}</li>
              <li>{t('moonMissionIntro.li4')}</li>
            </ul>
            <Trans
              i18nKey="moonMissionIntro.content1"
              components={[
                <p />,
              ]}
            />
            <img src={introLogoImg} alt="" />
          </>
        )
      }
    }
  }

  const renderLightBoxContent = () => {
    switch (tag) {
      case '2007_1': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2007.tag1.content"
            components={[
              <img src={img20071} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2007.tag1.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
        )
      }
      case '2007_2': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2007.tag2.content"
            components={[
              <img src={img20072} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2007.tag2.label')}
              </div>
            ]}
          />
        )
      }
      case '2010_1': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2010.tag1.content"
            components={[
              <img src={img20101} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2010.tag1.label')}
              </div>
            ]}
          />
        )
      }
      case '2010_2': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2010.tag2.content"
            components={[
              <img src={img20102} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2010.tag2.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
        )
      }
      case '2013_1': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2013.tag1.content"
            components={[
              <img src={ (i18n.language === 'en') ? img20131en : img20131} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2013.tag1.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
        )
      }
      case '2013_2': {
        return (
          <>
          <video muted={true} autoPlay={true}  loop={true} controls={false}>
              <source src={video20184} />
          </video>
          <Trans
            i18nKey="moonMissionIntro.2013.tag2.content"
            components={[
              <></>,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2013.tag2.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
          </>
        )
      }
      case '2014_1': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2014.tag1.content"
            components={[
              <img src={img20141} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2014.tag1.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
        )
      }
      case '2014_2': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2014.tag2.content"
            components={[
              <img src={img20142} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2014.tag2.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
        )
      }
      case '2014_3': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2014.tag3.content"
            components={[
              <img src={img20143} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2014.tag3.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
              <p className='moon-mission-intro__lightbox-content-thanks' />
            ]}
          />
        )
      }
      case '2018_1': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2018.tag1.content"
            components={[
              <img src={(i18n.language === 'en') ? img20181en : img20181} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2018.tag1.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
            ]}
          />
        )
      }
      case '2018_2': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2018.tag2.content"
            components={[
              <img src={(i18n.language === 'en') ? img20182en : img20182} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2018.tag2.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
              <p className='moon-mission-intro__lightbox-content-thanks' />
            ]}
          />
        )
      }
      case '2018_3': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2018.tag3.content"
            components={[
              <img src={img20183} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2018.tag3.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />
            ]}
          />
        )
      }
      case '2018_4': {
        return (
          <>
            <img src={img20184} alt="" />
            <Trans
              i18nKey="moonMissionIntro.2018.tag4.content"
              components={[
                <></>,
                <div className="moon-mission-intro__lightbox-title">
                  {t('moonMissionIntro.2018.tag4.label')}
                </div>,
                <p className="moon-mission-intro__lightbox-content" />,
                <p className='moon-mission-intro__lightbox-content-thanks' />,
              ]}
            /></>
        )
      }
      case '2020_1': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2020.tag1.content"
            components={[
              <img src={img20201} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2020.tag1.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />,
              <p className="moon-mission-intro__lightbox-content-thanks" />,
            ]}
          />
        )
      }
      case '2020_2': {
        return (
          <Trans
            i18nKey="moonMissionIntro.2020.tag2.content"
            components={[
              <img src={(i18n.language === 'en') ? img20202En : img20202} alt="" />,
              <div className="moon-mission-intro__lightbox-title">
                {t('moonMissionIntro.2020.tag2.label')}
              </div>,
              <p className="moon-mission-intro__lightbox-content" />
            ]}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <>
      <div className='moon-global__right-deco showAnimation'></div>
      <div className="moon-mission-intro">
        <div className={classNames('moon-mission-intro__title', 'showAnimation', {
          [`y${year}`]: year,
          "moon-mission-intro__title-en": (i18n.language === 'en'),
          "fadeOut": navigateToNext
        })} />
        <div className={ classNames("moon-mission-intro__text-box-container","showAnimation",
        {
          "fadeOut": navigateToNext
        }
        )}>
          <ScrollableViewWithFader className={classNames("moon-mission-intro__text-content", 
            {
              "moon-mission-intro__text-content-en": (i18n.language === 'en'),
            }
          )} noFader={year === null}>
            {renderContent()}
          </ScrollableViewWithFader>
          <div className="moon-mission-intro__timeline">
            <div
              className={classNames('moon-mission-intro__timeline-year y2007', {
                'active': year === 2007,
              })}
              onClick={() => setYear(2007)}
            >
              <div>{t('moonMissionIntro.2007.label')}</div>
            </div>
            <div
              className={classNames('moon-mission-intro__timeline-year y2010', {
                'active': year === 2010,
              })}
              onClick={() => setYear(2010)}
            >
              <div>{t('moonMissionIntro.2010.label')}</div>
            </div>
            <div
              className={classNames('moon-mission-intro__timeline-year y2013', {
                'active': year === 2013,
              })}
              onClick={() => setYear(2013)}
            >
              <div>{t('moonMissionIntro.2013.label')}</div>
            </div>
            <div
              className={classNames('moon-mission-intro__timeline-year y2014', {
                'active': year === 2014,
              })}
              onClick={() => setYear(2014)}
            >
              <div>{t('moonMissionIntro.2014.label')}</div>
            </div>
            <div
              className={classNames('moon-mission-intro__timeline-year y2018', {
                'active': year === 2018,
              })}
              onClick={() => setYear(2018)}
            >
              <div>{t('moonMissionIntro.2018.label')}</div>
            </div>
            <div
              className={classNames('moon-mission-intro__timeline-year y2020', {
                'active': year === 2020,
              })}
              onClick={() => setYear(2020)}
            >
              <div>{t('moonMissionIntro.2020.label')}</div>
            </div>
            <div className={
              classNames('moon-mission-intro__lottie-balls y2007',
                {
                  'active': (year === 2007)
                }
              )}>
            <Lottie 
              options={ball1Option}
              width={127}
              height={127} />
            </div>
            <div className={
              classNames('moon-mission-intro__lottie-balls y2010',
                {
                  'active': (year === 2010)
                }
              )}>
            <Lottie 
              options={ball1Option}
              width={127}
              height={127} />
            </div>
            <div className={
              classNames('moon-mission-intro__lottie-balls y2013',
                {
                  'active': (year === 2013)
                }
              )}>
            <Lottie 
              options={ball2Option}
              width={127}
              height={127} />
            </div>
            <div className={
              classNames('moon-mission-intro__lottie-balls y2014',
                {
                  'active': (year === 2014)
                }
              )}>
            <Lottie 
              options={ball2Option}
              width={127}
              height={127} />
            </div>
            <div className={
              classNames('moon-mission-intro__lottie-balls y2018',
                {
                  'active': (year === 2018)
                }
              )}>
            <Lottie 
              options={ball3Option}
              width={127}
              height={127} />
            </div>
            <div className={
              classNames('moon-mission-intro__lottie-balls y2020',
                {
                  'active': (year === 2020)
                }
              )}>
            <Lottie 
              options={ball3Option}
              width={127}
              height={127} />
            </div>
            <div className="moon-mission-intro__click-block y2007" onClick={() => setYear(2007)}
               onTouchEnd={() => setYear(2007)}></div>
            <div className="moon-mission-intro__click-block y2010" onClick={() => setYear(2010)}
               onTouchEnd={() => setYear(2010)}></div>
            <div className="moon-mission-intro__click-block y2013" onClick={() => setYear(2013)}
               onTouchEnd={() => setYear(2013)}></div>
            <div className="moon-mission-intro__click-block y2014" onClick={() => setYear(2014)}
               onTouchEnd={() => setYear(2014)}></div>
            <div className="moon-mission-intro__click-block y2018" onClick={() => setYear(2018)}
               onTouchEnd={() => setYear(2018)}></div>
            <div className="moon-mission-intro__click-block y2020" onClick={() => setYear(2020)}
               onTouchEnd={() => setYear(2020)}></div>
          </div>
        </div>
        <div className={classNames('moon-mission-intro__light-box', {
          'visible': tag
        })}>
          <div className={classNames(
            'moon-mission-intro__light-box-content customscroll',
            {
              "moon-mission-intro__light-box-content-en": (i18n.language === "en")
            }
          )}>
            {renderLightBoxContent()}
            <img
              className='moon-mission-intro__light-box-close-btn'
              src={closeBtn}
              alt=''
              onClick={(e) => {
                e.preventDefault();
                closeLightbox();
              }}
              onTouchEnd={(e) => {
                e.preventDefault();
                closeLightbox();
              }}
            />
          </div>
        </div>
      </div>

      <div className='moon-mission-intro__static-moon-container moon-mission-intro__static-moon-container-ani'>
        <img alt='' src={staticMoon1} className={
          classNames(
            "moon-mission-intro__static-moon-1",
            {
              "visible": (year === null || year === 2007 || year === 2010),
              "invisible": !(year === null || year === 2007 || year === 2010),
            }
          )
        } />
        {/* <img alt='' src={staticMoon2} className={
          classNames(
            "moon-mission-intro__static-moon-2",
            {
              "visible": (year === 2010),
              "invisible": !(year === 2010),
            }
          )
        } /> */}
        <img alt='' src={staticMoon3} className={
          classNames(
            "moon-mission-intro__static-moon-3",
            {
              "visible": (year === 2013),
              "invisible": !(year === 2013),
            }
          )
        }  />
        <img alt='' src={staticMoon4} className={
          classNames(
            "moon-mission-intro__static-moon-4",
            {
              "visible": (year === 2014 || year === 2020),
              "invisible": !(year === 2014),
            }
          )
        }   />
        <img alt='' src={staticMoon5} className={
          classNames(
            "moon-mission-intro__static-moon-5",
            {
              "visible": (year === 2018),
              "invisible": !(year === 2018),
            }
          )
        }   />
        {/* <img alt='' src={staticMoon6} className={
          classNames(
            "moon-mission-intro__static-moon-6",
            {
              "visible": (year === 2020),
              "invisible": !(year === 2020),
            }
          )
        }   /> */}
      </div>
      {(year === 2007) && <div className={
        classNames(
          'moon-mission-intro__ce1-lottie',
          "showAnimation",
          {
            "fadeOut": navigateToNext
          }
        )
      }>
        <Lottie options={moonRover1Option} />
      </div>}
      {(year === 2010 && !lightboxEnabled && <div className={classNames(
        'moon-mission-intro__ce2-video',
        'moon-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission2OverlayVideo} />
        </video>
      </div>)}
      {(year === 2013 && !lightboxEnabled && <div className={classNames(
        'moon-mission-intro__ce3-video',
        'moon-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission3OverlayVideo} />
        </video>
      </div>)}
      {(year === 2014 && !lightboxEnabled  && <div className={classNames(
        'moon-mission-intro__ce4-video',
        'moon-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission4OverlayVideo} />
        </video>
      </div>)}
      {(year === 2018 && !lightboxEnabled && <div className={classNames(
        'moon-mission-intro__ce5-video',
        'moon-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission5OverlayVideo} />
        </video>
      </div>)}
      {(year === 2020 && !lightboxEnabled && <div className={classNames(
        'moon-mission-intro__ce6-video',
        'moon-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission6OverlayVideo} />
        </video>
      </div>)}
    </>
  )
}

export default MoonMissionIntro
