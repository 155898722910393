/* eslint-disable jsx-a11y/heading-has-content */
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';


import ScrollableViewWithFader from '../../components/scrollable-view-with-fader';

import marsVisionImg1 from '../../img/mars-vision/img1.jpg';
import marsVisionImg2 from '../../img/mars-vision/img2.jpg';

const HO3SpecialComponent = () => {
  const { t } = useTranslation();
  return (
    <p>
      { t ('marsVision.content1-1') }
      HO<sub>3</sub>
      { t ('marsVision.content1-2') }
    </p>
  )
}

const ScrollContentRenderer = () => {
  return (
    <div>
      { /* eslint-disable-next-line */}
      <a id="tab1" />
      <Trans
        i18nKey="marsVision.content1"
        components={[
          <h2 />,
          <p />,
          <h3 />,
          <img src={marsVisionImg1} alt="" />,
          <img src={marsVisionImg2} alt="" />,
          <HO3SpecialComponent />
        ]}
      />
      <div className='mars-vision__scroll-content-divider'></div>
      { /* eslint-disable-next-line */}
      <a id="tab2"></a>
      <Trans
        i18nKey="marsVision.content2"
        components={[
          <h2 />,
          <p />,
          <h3 />
        ]}
      />
      <div className='mars-vision__scroll-content-padder'></div>
    </div>
  );
}

const MarsVision = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className='mars-global__right-deco'></div>
      <div className="mars-vision showAnimation">
        <div className={
          classNames(
            "mars-vision__title",
            {
              "mars-vision__title-en": (i18n.language === 'en')
            }
          )
        } />
        <div className="mars-vision__text-box-container">
          <ScrollableViewWithFader anchorPoint={currentTab} className={
            classNames(
              "mars-vision__text-content",
              {
                "mars-vision__text-content-en": (i18n.language === 'en')
              }
            )
          }
            onScrollOverTag={(n) => {
              console.log(n);
              setActiveTab(n + 1);
            }}>
            <ScrollContentRenderer />
          </ScrollableViewWithFader>
        </div>
        <div className="mars-vision__tab-box-container">
          <div className={
            classNames(
              "mars-vision__tab-box-content",
              {
                "mars-vision__tab-box-content-en": (i18n.language === 'en')
              }
            )
          }>
            <div
              className={classNames({ 'active': activeTab === 1 })}
              onClick={() => setCurrentTab(1)}
              onTouchEnd={() => setCurrentTab(1)}
            >
              {t('marsVision.tab1')}
            </div>
            <div
              className={classNames({ 'active': activeTab === 2 })}
              onClick={() => setCurrentTab(2)}
              onTouchEnd={() => setCurrentTab(2)}
            >
              {t('marsVision.tab2')}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MarsVision;
