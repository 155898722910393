import Lottie from 'react-lottie';
import * as MarsTitle from '../../lottie/idle/00_Landing_MarsTitle.json';
import * as MoonTitle from '../../lottie/idle/00_Landing_MoonTitle.json';
import landingDecoImg from '../../img/landing/Decoration_05BG.png';

import * as decoLot3 from '../../lottie/idle/Decoration_03.json';
import * as decoLot4 from '../../lottie/idle/Decoration_02.json';
import * as decoLot1 from '../../lottie/idle/Decoration_01.json';
import { useTravel } from '../../context/travelContext';
import { useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Const from '../../const/const';

const IdleMenu = () => {

  const { travelTo } = useTravel();
  const [ navigateToNext, setNavigateToNext ] = useState(false);

  const marsTitleOption = {
    loop: true,
    autoplay: true,
    animationData: MarsTitle,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const moonTitleOption = {
    loop: true,
    autoplay: true,
    animationData: MoonTitle,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const decoLot3Option = {
    loop: true,
    autoplay: true,
    animationData: decoLot3,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin slice'
    }
  }

  const decoLot4Option = {
    loop: true,
    autoplay: true,
    animationData: decoLot4,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin slice'
    }
  }

  const decoLot1Option = {
    loop: true,
    autoplay: true,
    animationData: decoLot1,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin slice'
    }
  }

  const navigateToMoon = () => {
    setNavigateToNext(true);
    travelTo({value:'/moon-menu'});
  }

  const navigateToMars = () => {
    setNavigateToNext(true);
    travelTo({value:'/mars-menu'});
  }

  const dNavigateToMoon = debounce(navigateToMoon, Const.debounceValue);
  const dNavigateToMars = debounce(navigateToMars, Const.debounceValue);

  return (
    <>
      <div className={
        classNames(
          'idle-menu__shadow-layer', 'showAnimation',
          {"fadeOut": navigateToNext}
        )}>
        <div className='idle-menu__moon-shadow-layer'></div>
        <div className='idle-menu__mars-shadow-layer'></div>
      </div>
      <div className={
        classNames(
          'idle-menu__upper-shadow-layer', 'showAnimation',
          {"fadeOut": navigateToNext}
        )}>
        <div className='idle-menu__moon-upper-shadow-layer'></div>
        <div className='idle-menu__mars-upper-shadow-layer'></div>
      </div>
      <div className={classNames("idle-menu__bg-decor-container", "showAnimation", 
        {"fadeOut": navigateToNext})}>
        <img src={landingDecoImg} alt='' />
      </div>
      
      <div className="idle-menu">
        
        <div className="idle-menu__lottie-container">
        
          <div className={classNames("idle-menu__lottie-deco4-container", "showAnimation", 
          {"fadeOut": navigateToNext})}>
            <Lottie options={decoLot4Option}
              width={197}
              height={603} />
          </div>
          <div className={classNames("idle-menu__lottie-deco3-container", "showAnimation", 
            {"fadeOut": navigateToNext})}>
            <Lottie options={decoLot3Option}
              width={368}
              height={1293} />
          </div>
          <div className={classNames("idle-menu__lottie-deco1-container", "showAnimation", 
            {"fadeOut": navigateToNext})}>
            <Lottie options={decoLot1Option}
              width={2160}
              height={3840} />
          </div>
        </div>
        <div className="idle-menu__groups-container">
          <div className='idle-menu__moon-group'>
          </div>
          <div className='idle-menu__mars-group'></div>
        </div>
        <div className={classNames("idle-menu__items", {
          "fadeOut": navigateToNext
        })}>
          <div className="idle-menu__item">
              <div className="idle-menu__moon-container idle-menu__button-container showAnimation" onClick={() => {
                dNavigateToMoon();
              }} onTouchEnd={() => {
                dNavigateToMoon();
              }}>
                <div className="idle-menu__moon-lottie">
                  <Lottie options={moonTitleOption}
                    width={557}
                    height={858} />
                </div>
              </div>
          </div>
          <div className="idle-menu__item">
              <div className="idle-menu__mars-container idle-menu__button-container showAnimation" onClick={() => {
                dNavigateToMars();
              }}
              onTouchEnd={() => {
                dNavigateToMars();
              }}>
                <div className="idle-menu__mars-lottie">
                  <Lottie options={marsTitleOption}
                    width={557}
                    height={858} />
                </div>
              </div>
          </div>
        </div>
        
      </div>
      <div className={classNames("idle-menu__friendly", "showAnimation", 
            {"fadeOut": navigateToNext})}></div>
    </>

  )
}

export default IdleMenu
