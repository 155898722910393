import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLightbox } from '../../context/lightboxContext';
import { useTravel } from '../../context/travelContext';
import Lottie from 'react-lottie'; 
import * as largeLottie from '../../lottie/moon-menu/MoonMenu_Spiral_L.json';
import * as smallLottie from '../../lottie/moon-menu/MoonMenu_Spiral_S.json';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Const from '../../const/const';

const smallLottieOption = {
  loop: true,
  autoplay: true,
  animationData: smallLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMinYMin slice'
  }
}
const largeLottieOption = {
  loop: true,
  autoplay: true,
  animationData: largeLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMinYMin slice'
  }
}

const PageLayout = ({ children }) => {

  const { travelValue, travelTo, travelToPrev } = useTravel();
  
  const location = useLocation();
  const { i18n } = useTranslation();
  const { lightboxEnabled, disableLightbox } = useLightbox();
  const [showBackButton, setShowBackButton] = useState(false);
  const isAtIdleMenu = location.pathname === '/';
  const isAtMenu = location.pathname === '/'
    || location.pathname === '/moon-menu'
    || location.pathname === '/mars-menu';
  const hideMoon = (!(travelValue === "/") && travelValue.indexOf('/mars') >= 0);
  const hideMoon2 = (
    travelValue === "/moon-rover"
    || travelValue === "/moon-vision"
  );
  const hideMoon3 = (
    location.pathname === "/moon-link-with-hk" 
    || location.pathname === "/moon-photo-gallary"
    || travelValue === "/moon-link-with-hk" 
    || travelValue === "/moon-photo-gallary"
  );
  const hideMars = (!(travelValue === "/") && travelValue.indexOf('/moon') >= 0);
  const hideMars2 = location.pathname === "/mars-rover"
    || location.pathname === "/mars-vision"
    || travelValue === "/mars-rover"
    || travelValue === "/mars-vision";
  const hideMars3 = location.pathname === "/mars-link-with-hk" 
    || location.pathname === "/mars-photo-gallary"
    || travelValue === "/mars-link-with-hk" 
    || travelValue === "/mars-photo-gallary";

  const marsBg = (location.pathname.indexOf('/mars') >= 0 || travelValue.indexOf('/mars') >= 0);
  const moonBg = (location.pathname.indexOf('/moon') >= 0 || travelValue.indexOf('/moon') >= 0);

  const redirectToPreviousPage = () => {
    // navigate(-1);
    travelToPrev();
  };

  const redirectToIdleMenu = (delay = 0) => {
    disableLightbox();
    travelTo({value: '/', delay});
  }

  const changeLanguage = (e, lang) => {
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    if (isAtMenu || lightboxEnabled) {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  }, [lightboxEnabled, isAtMenu]);

  const debouncedChangeLanguage = debounce(changeLanguage, Const.debounceValue);
  const dRedirectToPreviousPage = debounce(redirectToPreviousPage, Const.debounceValue);
  const dRedirectToIdleMenu = debounce(redirectToIdleMenu, Const.debounceValue);
  
  return (
    <div className="page-layout">
      <div className="page-layout__background-container"></div>
      <div className={"page-layout__mars-background-container " 
        + ((marsBg) ? 'page-layout__show-mars-bg' : '')
        + ((moonBg) ? 'page-layout__show-moon-bg' : '')
      }></div>
      <div className={
        classNames(
          "page-layout__small-lottie-container",
          {
            "page-layout__small-lottie-moon-menu": (['/moon-menu', '/mars-menu'].indexOf(travelValue) >= 0),
            "page-layout__small-lottie-moon-mission": ([
              '/moon-mission', '/moon-vision', '/moon-link-with-hk', '/moon-mission-intro',
              '/mars-mission', '/mars-vision', '/mars-link-with-hk', '/mars-mission-intro',
            ].indexOf(travelValue) >= 0)
          }
        )
        }>
        <Lottie 
            options={smallLottieOption}
           width={2313}
           height={2313} />
      </div>
      <div className={
        classNames(
          "page-layout__large-lottie-container",
          {
            "page-layout__large-lottie-moon-menu": (['/moon-menu', '/mars-menu'].indexOf(travelValue) >= 0),
          }
        )
        }>
        <Lottie 
            options={largeLottieOption}
           width={4427}
           height={4427} />
      </div>
      <div className={"page-layout__rotating-moon-container "+
        ((travelValue === '/')?'idle':'')+
        ((travelValue === '/moon-menu')?' moon_menu':'')+
        ((travelValue === '/moon-mission')?' moon_mission':'')+
        ((travelValue === '/moon-mission-intro')?' moon_mission_intro':'')+
        ((location.pathname === '/moon-mission-intro')?' moon_mission_intro_2':'')+     // Dont change!!!!
        ((hideMoon2)?' moon_hide2':'')+
        ((hideMoon3)?' moon_hide3':'')+
        ((hideMoon)?' moon_hide':'')
        }>
        <video muted={true} className="page-layout__rotating-moon" preload='auto' autoPlay={true} loop={true}>
          <source src={`${process.env.PUBLIC_URL}/video/landing/Moon_rotate.webm`} />
        </video>
      </div>
      <div className={"page-layout__rotating-mars-container "+
      ((travelValue === '/')?'idle':'')+
      ((location.pathname === '/mars-menu' || travelValue === '/mars-menu')?' mars_menu':'')+
      ((location.pathname === '/mars-mission' || travelValue === '/mars-mission')?' mars_mission':'')+
      ((location.pathname === '/mars-mission-intro' || travelValue === '/mars-mission-intro')?' mars_mission_intro':'')+
      ((hideMars2)?' mars_hide2':'')+
      ((hideMars3)?' mars_hide3':'')+
      ((hideMars === true)?'mars_hide':'')}>
        <video muted={true} className="page-layout__rotating-mars"  preload='auto' autoPlay={true} loop={true}>
          <source src={`${process.env.PUBLIC_URL}/video/landing/Mars_rotate.webm`} />
        </video>
      </div>
      {children}
      {(
        <>
          {<div className={classNames("page-layout__to-menu", {
            'page-layout__to-menu-visible': showBackButton
          })} onClick={dRedirectToPreviousPage} onTouchEnd={dRedirectToPreviousPage} />}
          <div className={"page-layout__to-idle-menu " + ((isAtIdleMenu)?'hidden':'')} onClick={dRedirectToIdleMenu} onTouchEnd={dRedirectToIdleMenu} />
          <div className={"page-layout__locale " + ((isAtIdleMenu)?'hidden':'')}> 
            {
              i18n.language === 'zh'
                ? <div className="page-layout__locale-button" onClick={(e) => debouncedChangeLanguage(e, 'en')} onTouchEnd={(e) => debouncedChangeLanguage(e, 'en')} ><span>EN</span></div>
                : <div className="page-layout__locale-button" onClick={(e) => debouncedChangeLanguage(e, 'zh')} onTouchEnd={(e) => debouncedChangeLanguage(e, 'zh')} ><span>中</span></div>
            }
          </div>
        </>
      )}
    </div>
  )
}

export default PageLayout
