import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

const ScrollableViewWithFader = ({ children, anchorPoint, className, onScrollOverTag, noFader }) => {

    const scrollViewRef = useRef(null);

    const [showUpperFader, setShowUpperFader] = useState(false);
    const [showLowerFader, setShowLowerFader] = useState(true);

    const lastCallbackValue = useRef(0);

    useEffect(() => {
        if (noFader) {
            setShowUpperFader(false);
            setShowLowerFader(false);
        } else {
            checkHeight();
        }
    }, [noFader, children]);

    useEffect(() => {
        if (anchorPoint) {

            switch (anchorPoint) {
                case 2:
                    const st2 = document.querySelector('a#tab2').offsetTop;
                    scrollViewRef.current.scrollTop = st2;
                    break;
                case 3:
                    const st3 = document.querySelector('a#tab3').offsetTop;
                    scrollViewRef.current.scrollTop = st3;
                    break;
                default:
                    scrollViewRef.current.scrollTop = 0;
                    break;
            }
        }
    }, [anchorPoint]);

    const checkHeight = () => {
        const obj = scrollViewRef.current;
        const scrollHeight = obj.scrollHeight;
        const height = obj.clientHeight;
        if (scrollHeight > height) {
            setShowUpperFader(false);
            setShowLowerFader(true);
        } else {
            setShowLowerFader(false);
        }
    }

    const onScrollListener = (e) => {
        if (noFader) {
            return;
        }
        const obj = scrollViewRef.current;
        const scrollTop = obj.scrollTop;
        var anchors = obj.querySelectorAll('a');
        if (anchors && anchors.length > 0) {
            var arr = [];
            anchors.forEach(element => {
                arr.push(element.offsetTop);
            });
            if (arr.length > 0) {
                var n = 0;
                
                for(var i = 0; i < arr.length; i++){
                    if (scrollTop >= arr[i]) {
                        n = i;
                    }
                }
                var callbackValue = Math.max(0, n);
                if (lastCallbackValue.current !== callbackValue) {
                    lastCallbackValue.current = callbackValue
                    onScrollOverTag(callbackValue);
                }
                
            }
        }
        if (obj.scrollTop === 0) {
            setShowUpperFader(false);
            setShowLowerFader(true);
            checkHeight();
        } else {
            setShowUpperFader(true);
        }
        if (Math.abs(obj.scrollTop - (obj.scrollHeight - obj.offsetHeight)) < 5 ) {
            setShowLowerFader(false);
        } else {
            setShowLowerFader(true);
            checkHeight();
        }
    }

    return (
        <div
            className={
                classNames(
                    className,
                    "scrollable-view-with-fader__container"
                )}
        >
            <div className="customscroll scrollable-view-with-fader__scroll-container"
                ref={scrollViewRef}
                onScroll={onScrollListener}>
                <div>
                    {children}
                </div>
            </div>
            {(showUpperFader) ? <div className="scrollable-view-with-fader__upper-fader scrollable-view-with-fader__fader"></div> : <></>}
            {(showLowerFader) ? <div className="scrollable-view-with-fader__lower-fader scrollable-view-with-fader__fader"></div> : <></>}
        </div>
    );
}

export default ScrollableViewWithFader;