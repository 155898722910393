import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import closeBtn from '../../img/mars-mission-intro/close-btn.png';
import ce1InlineImg from '../../img/mars-mission-intro/ce1-inline-img.jpg';
import ce1InlineEnImg from '../../img/mars-mission-intro/ce1-inline-img-en.jpg';
import ce2InlineImg from '../../img/mars-mission-intro/ce2-inline-img.jpg';
import ce3InlineImg from '../../img/mars-mission-intro/ce3-inline-img.jpg';
import ce4InlineImg from '../../img/mars-mission-intro/ce4-inline-img.jpg';
import CeInlineButton from '../../components/ce-inline-button';

import img20071 from '../../img/mars-mission-intro/img-2007-1.jpg';
import video20072 from '../../video/Mars_mission01_detail_02_1.webm';
import img20101 from '../../img/mars-mission-intro/img-2010-1.jpg';
import img20101en from '../../img/mars-mission-intro/img-2010-1-en.jpg';
import img20102 from '../../img/mars-mission-intro/img-2010-2.jpg';
import video20131 from '../../video/Mars_mission03_detail_01_1.webm';
import video20132 from '../../video/Mars_mission03_detail_02_1.webm';
import video20133 from '../../video/Mars_mission03_detail_03_1.webm';
import img20141 from '../../img/mars-mission-intro/img-2014-1.jpg';
import video201402 from '../../video/Mars_mission04_detail_02_1.webm';
import img20143 from '../../img/mars-mission-intro/img-2014-3.jpg';
import img20144 from '../../img/mars-mission-intro/img-2014-4.jpg';
import video20145 from '../../video/Mars_mission04_deatil_04_1.webm';

import * as whiteBall from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_white.json';
import * as ball1 from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_01-02.json';
import * as ball2 from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_03-04.json';
import * as ball3 from '../../lottie/balls/03.1.2_Moon_Mission_Timeline_05-06.json';

// import * as CE1AD from '../../lottie/mars-mission-intro/03.1.2_Moon_Mission_ChangE-1.json';
// import mission2OverlayVideo from '../../video/mission-03-overlay-video/03.1.3_Moon_Mission_ChangE-2.webm';
// import mission3OverlayVideo from '../../video/mission-03-overlay-video/03.1.2_Moon_Mission_ChangE-3.webm';
// import mission4OverlayVideo from '../../video/mission-03-overlay-video/03.1.5_Moon_Mission_ChangE-4.webm';
// import mission5OverlayVideo from '../../video/mission-03-overlay-video/03.1.6_Moon_Mission_ChangE-5.webm';
// import mission6OverlayVideo from '../../video/mission-03-overlay-video/03.1.7_Moon_Mission_ChangE-6.webm';
import ScrollableViewWithFader from '../../components/scrollable-view-with-fader';
import { useLightbox } from '../../context/lightboxContext';
import { useTravel } from '../../context/travelContext';

import staticMars from '../../img/mars-mission-intro/static-mars.png';

// import staticMoon1 from '../../img/mars-mission-intro/static-mars-1.png';
// import staticMoon2 from '../../img/mars-mission-intro/static-mars-2.png';
// import staticMoon3 from '../../img/mars-mission-intro/static-mars-3.png';
// import staticMoon4 from '../../img/mars-mission-intro/static-mars-4.png';
// import staticMoon5 from '../../img/mars-mission-intro/static-mars-5.png';
// import staticMoon6 from '../../img/mars-mission-intro/static-mars-6.png';

import introLogoImg from '../../img/mars-mission-intro/intro-logo.jpg';

import mission1OverlayVideo from '../../video/mars-overlay-video/MarsMission_01_Animation.webm';
import mission2OverlayVideo from '../../video/mars-overlay-video/MarsMission_02_Animation.webm';
import mission3OverlayVideo from '../../video/mars-overlay-video/MarsMission_03_Animation.webm';
import mission4OverlayVideo from '../../video/mars-overlay-video/MarsMission_04_Animation.webm';

const pageRouteName = '/mars-mission-intro';

const whiteBallOption = {
  loop: true,
  autoplay: true,
  animationData: whiteBall,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const ball1Option = {
  loop: true,
  autoplay: true,
  animationData: ball1,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const ball2Option = {
  loop: true,
  autoplay: true,
  animationData: ball2,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const ball3Option = {
  loop: true,
  autoplay: true,
  animationData: ball3,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const MarsMissionIntro = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [year, setYear] = useState(null);
  const [tag, setTag] = useState(null);
  const { enabledLightbox, disableLightbox, lightboxEnabled } = useLightbox();
  const { travelValue } = useTravel();
  const [navigateToNext, setNavigateToNext] = useState(false);

  // const moonRover1Option = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: CE1AD,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // }

  useEffect(() => {
    if (travelValue !== pageRouteName) {
      setNavigateToNext(true);
    } else {
      setNavigateToNext(false);
    }
  }, [travelValue]);

  useEffect(() => {
    if (location.state && location.state.year) {
      setYear(location.state.year);
    }
  }, [location.state]);

  useEffect(() => {
    document.querySelector('.customscroll').scrollTop = 0;
  }, [year])

  const openLightbox = (tag) => {
    enabledLightbox();
    setTag(tag);
  }

  const closeLightbox = () => {
    disableLightbox();
    setTag(null);
  }

  const renderContent = () => {
    switch (year) {
      case 2007: {
        return (
          <>
            <Trans
              i18nKey="marsMissionIntro.2007.content"
              components={[
                <></>,
                <p />
              ]}
            />
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.2007.tag1.label')} onClick={() => openLightbox('2007_1')} />
            </div>
            <Trans
              i18nKey="marsMissionIntro.2007.content2"
              components={[
                <></>,
                <p />
              ]}
            />
            <img src={(i18n.language === 'en') ? ce1InlineEnImg : ce1InlineImg} alt='' />
            <p></p>
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.2007.tag2.label')} onClick={() => openLightbox('2007_2')} />
            </div>
          </>
        )
      }
      case 2010: {
        return (
          <>
            <img src={ce2InlineImg} alt="" />,
            <Trans
              i18nKey="marsMissionIntro.2010.content"
              components={[
                <p />,
              ]}
            />
            <Trans
              i18nKey="marsMissionIntro.2010.content2"
              components={[
                <p />,
              ]}
            />
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.2010.tag1.label')} onClick={() => openLightbox('2010_1')} />
              <CeInlineButton languageKey={t('marsMissionIntro.2010.tag2.label')} onClick={() => openLightbox('2010_2')} />
            </div>
            <p></p>

          </>
        )
      }
      case 2013: {
        return (
          <>
            <img src={ce3InlineImg} alt="" />
            <Trans
              i18nKey="marsMissionIntro.2013.content"
              components={[
                <p />
              ]}
            />
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.2013.tag1.label')} onClick={() => openLightbox('2013_1')} />
              <CeInlineButton languageKey={t('marsMissionIntro.2013.tag2.label')} onClick={() => openLightbox('2013_2')} />
              <CeInlineButton languageKey={t('marsMissionIntro.2013.tag3.label')} onClick={() => openLightbox('2013_3')} />
            </div>
          </>
        )
      }
      case 2014: {
        return (
          <>
            <img src={ce4InlineImg} alt="" />
            <Trans
              i18nKey="marsMissionIntro.2014.content"
              components={[
                <p />
              ]}
            />
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.2014.tag1.label')} onClick={() => openLightbox('2014_1')} />
            </div>
            <Trans
              i18nKey="marsMissionIntro.2014.content1"
              components={[
                <p />
              ]}
            />
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.2014.tag2.label')} onClick={() => openLightbox('2014_2')} />
              <CeInlineButton languageKey={t('marsMissionIntro.2014.tag3.label')} onClick={() => openLightbox('2014_3')} />
              <CeInlineButton languageKey={t('marsMissionIntro.2014.tag4.label')} onClick={() => openLightbox('2014_4')} />
              <CeInlineButton languageKey={t('marsMissionIntro.2014.tag5.label')} onClick={() => openLightbox('2014_5')} />
            </div>
          </>
        )
      }
      default: {
        return (
          <>
            <Trans
              i18nKey="marsMissionIntro.content"
              components={[
                <p />,
              ]}
            />
            <Trans
              i18nKey="marsMissionIntro.li-title"
              components={[
                <p className='mars-mission-intro__li-title' />,
              ]}
            />
            <ul>
              <li>{t('marsMissionIntro.li1')}</li>
              <li>{t('marsMissionIntro.li2')}</li>
              <li>{t('marsMissionIntro.li3')}</li>
              <li>{t('marsMissionIntro.li4')}</li>
              <li>{t('marsMissionIntro.li5')}</li>
            </ul>
            <Trans
              i18nKey="marsMissionIntro.content1"
              components={[
                <p />,
              ]}
            />
            <div className="mars-mission-intro__tags">
              <CeInlineButton languageKey={t('marsMissionIntro.intro.label')} onClick={() => openLightbox('intro_1')} />
            </div>
            <p></p>
            <img src={introLogoImg} alt="" />
          </>
        )
      }
    }
  }

  const renderLightBoxContent = () => {
    switch (tag) {
      case 'intro_1': {
        return (
          <>
            <div className={classNames(
              "mars-mission-intro__lightbox-title",
              {
                "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
              }
            )}>
              {t('marsMissionIntro.intro.label')}
            </div>
            <Trans
              i18nKey="marsMissionIntro.intro.content"
              components={[
                <></>,
                <p className={
                  classNames(
                    "mars-mission-intro__lightbox-content",
                    {
                      "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                    }
                  )} />,
              ]}
            />
          </>
        )
      }
      case '2007_1': {
        return (
          <Trans
            i18nKey="marsMissionIntro.2007.tag1.content"
            components={[
              <img src={img20071} alt="" />,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2007.tag1.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
            ]}
          />
        )
      }
      case '2007_2': {
        return (
          <>
            <video muted={true} autoPlay={true} loop={true} preload='auto'>
              <source src={video20072} />
            </video>
            <div className={classNames(
              "mars-mission-intro__lightbox-title",
              {
                "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
              }
            )}>
              {t('marsMissionIntro.2007.tag2.label')}
            </div>
            <Trans
              i18nKey="marsMissionIntro.2007.tag2.content"
              components={[
                <p className={
                  classNames(
                    "mars-mission-intro__lightbox-content",
                    {
                      "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                    }
                  )} />,
              ]}
            />
          </>
        )
      }
      case '2010_1': {
        return (
          <Trans
            i18nKey="marsMissionIntro.2010.tag1.content"
            components={[
              <img src={(i18n.language === 'en') ? img20101en : img20101} alt="" />,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2010.tag1.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
            ]}
          />
        )
      }
      case '2010_2': {
        return (
          <Trans
            i18nKey="marsMissionIntro.2010.tag2.content"
            components={[
              <img src={img20102} alt="" />,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2010.tag2.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
            ]}
          />
        )
      }
      case '2013_1': {
        return (
          <>
            <video muted={true} autoPlay={true} loop={true} preload="auto">
              <source src={video20131} />
            </video>
            <Trans
              i18nKey="marsMissionIntro.2013.tag1.content"
              components={[
                <></>,
                <div className={classNames(
                  "mars-mission-intro__lightbox-title",
                  {
                    "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                  }
                )} >
                  {t('marsMissionIntro.2013.tag1.label')}
                </div>,
                <p className={
                  classNames(
                    "mars-mission-intro__lightbox-content",
                    {
                      "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                    }
                  )} />,
              ]}
            />
          </>
        )
      }
      case '2013_2': {
        return (
          <>
            <video muted={true} autoPlay={true} loop={true} preload="auto">
              <source src={video20132} />
            </video>
            <Trans
              i18nKey="marsMissionIntro.2013.tag2.content"
              components={[
                <></>,
                <div className={classNames(
                  "mars-mission-intro__lightbox-title",
                  {
                    "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                  }
                )} >
                  {t('marsMissionIntro.2013.tag2.label')}
                </div>,
                <p className={
                  classNames(
                    "mars-mission-intro__lightbox-content",
                    {
                      "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                    }
                  )} />,
              ]}
            />
          </>
        )
      }
      case '2013_3': {
        return (
          <>
            <video muted={true} autoPlay={true} loop={true} preload="auto">
              <source src={video20133} />
            </video>
            <Trans
              i18nKey="marsMissionIntro.2013.tag3.content"
              components={[
                <></>,
                <div className={classNames(
                  "mars-mission-intro__lightbox-title",
                  {
                    "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                  }
                )} >
                  {t('marsMissionIntro.2013.tag3.label')}
                </div>,
                <p className={
                  classNames(
                    "mars-mission-intro__lightbox-content",
                    {
                      "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                    }
                  )} />,
              ]}
            />
          </>
        )
      }
      case '2014_1': {
        return (
          <Trans
            i18nKey="marsMissionIntro.2014.tag1.content"
            components={[
              <img src={img20141} alt="" />,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2014.tag1.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
              <p className={
                classNames(
                  'mars-mission-intro__lightbox-content-thanks',
                  {
                    "mars-mission-intro__lightbox-content-thanks-en": (i18n.language === 'en')
                  }
                )} />
            ]}
          />
        )
      }
      case '2014_2': {
        return (<>
          <video muted={true} autoPlay={true} loop={true} preload='auto'>
            <source src={video201402} />
          </video>
          <Trans
            i18nKey="marsMissionIntro.2014.tag2.content"
            components={[
              <></>,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2014.tag2.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
            ]}
          />
        </>
        )
      }
      case '2014_3': {
        return (
          <Trans
            i18nKey="marsMissionIntro.2014.tag3.content"
            components={[
              <img src={img20143} alt="" />,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2014.tag3.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
              <p className={
                classNames(
                  'mars-mission-intro__lightbox-content-thanks',
                  {
                    "mars-mission-intro__lightbox-content-thanks-en": (i18n.language === 'en')
                  }
                )} />
            ]}
          />
        )
      }
      case '2014_4': {
        return (
          <Trans
            i18nKey="marsMissionIntro.2014.tag4.content"
            components={[
              <img src={img20144} alt="" />,
              <div className={classNames(
                "mars-mission-intro__lightbox-title",
                {
                  "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                }
              )}>
                {t('marsMissionIntro.2014.tag4.label')}
              </div>,
              <p className={
                classNames(
                  "mars-mission-intro__lightbox-content",
                  {
                    "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                  }
                )} />,
              <p className={
                classNames(
                  'mars-mission-intro__lightbox-content-thanks',
                  {
                    "mars-mission-intro__lightbox-content-thanks-en": (i18n.language === 'en')
                  }
                )} />
            ]}
          />
        )
      }
      case '2014_5': {
        return (
          <>
            <video muted={true} autoPlay={true} loop={true} preload='auto'>
              <source src={video20145} />
            </video>
            <Trans
              i18nKey="marsMissionIntro.2014.tag5.content"
              components={[
                <></>,
                <div className={classNames(
                  "mars-mission-intro__lightbox-title",
                  {
                    "mars-mission-intro__lightbox-title-en": (i18n.language === 'en')
                  }
                )}>
                  {t('marsMissionIntro.2014.tag5.label')}
                </div>,
                <p className={
                  classNames(
                    "mars-mission-intro__lightbox-content",
                    {
                      "mars-mission-intro__lightbox-content-en": (i18n.language === 'en')
                    }
                  )} />,
                <p className={
                  classNames(
                    'mars-mission-intro__lightbox-content-thanks',
                    {
                      "mars-mission-intro__lightbox-content-thanks-en": (i18n.language === 'en')
                    }
                  )} />
              ]}
            />
          </>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <>
      <div className='mars-global__right-deco'></div>
      <div className="mars-mission-intro">
        <div className={classNames('mars-mission-intro__title', 'showAnimation', {
          [`y${year}`]: year,
          "mars-mission-intro__title-en": (i18n.language === 'en'),
          "fadeOut": navigateToNext
        })} />
        <div className={classNames("mars-mission-intro__text-box-container", "showAnimation",
          {
            "fadeOut": navigateToNext
          }
        )}>
          <ScrollableViewWithFader className={classNames("mars-mission-intro__text-content",
            {
              "mars-mission-intro__text-content-en": (i18n.language === 'en'),
            }
          )}>
            {renderContent()}
          </ScrollableViewWithFader>
          <div className="mars-mission-intro__timeline">
            <div
              className={classNames('mars-mission-intro__timeline-year y2007', {
                'active': year === 2007,
              })}
              onClick={() => setYear(2007)}
              onTouchEnd={() => setYear(2007)}
            >
              <div>{t('marsMissionIntro.2007.label')}</div>
            </div>
            <div
              className={classNames('mars-mission-intro__timeline-year y2010', {
                'active': year === 2010,
              })}
              onClick={() => setYear(2010)}
              onTouchEnd={() => setYear(2010)}
            >
              <div>{t('marsMissionIntro.2010.label')}</div>
            </div>
            <div
              className={classNames('mars-mission-intro__timeline-year y2013', {
                'active': year === 2013,
              })}
              onClick={() => setYear(2013)}
              onTouchEnd={() => setYear(2013)}
            >
              <div>{t('marsMissionIntro.2013.label')}</div>
            </div>
            <div
              className={classNames('mars-mission-intro__timeline-year y2014', {
                'active': year === 2014,
              })}
              onClick={() => setYear(2014)}
              onTouchEnd={() => setYear(2014)}
            >
              <div>{t('marsMissionIntro.2014.label')}</div>
            </div>
            <div className={
              classNames('mars-mission-intro__lottie-balls y2007',
                {
                  'active': (year === 2007)
                }
              )}>
              <Lottie
                options={whiteBallOption}
                width={127}
                height={127} />
            </div>
            <div className={
              classNames('mars-mission-intro__lottie-balls y2010',
                {
                  'active': (year === 2010)
                }
              )}>
              <Lottie
                options={ball1Option}
                width={127}
                height={127} />
            </div>
            <div className={
              classNames('mars-mission-intro__lottie-balls y2013',
                {
                  'active': (year === 2013)
                }
              )}>
              <Lottie
                options={ball2Option}
                width={127}
                height={127} />
            </div>
            <div className={
              classNames('mars-mission-intro__lottie-balls y2014',
                {
                  'active': (year === 2014)
                }
              )}>
              <Lottie
                options={ball3Option}
                width={127}
                height={127} />
            </div>
            <div className="mars-mission-intro__click-block y2007" onClick={() => setYear(2007)}
              onTouchEnd={() => setYear(2007)}></div>
            <div className="mars-mission-intro__click-block y2010" onClick={() => setYear(2010)}
              onTouchEnd={() => setYear(2010)}></div>
            <div className="mars-mission-intro__click-block y2013" onClick={() => setYear(2013)}
              onTouchEnd={() => setYear(2013)}></div>
            <div className="mars-mission-intro__click-block y2014" onClick={() => setYear(2014)}
              onTouchEnd={() => setYear(2014)}></div>
          </div>
        </div>
        <div className={classNames('mars-mission-intro__light-box', {
          'visible': tag,
          "y2007": (year === 2007),
          "y2010": (year === 2010),
          "y2013": (year === 2013),
          "y2014": (year === 2014)
        })}>
          <div className='mars-mission-intro__light-box-content customscroll'>
            {renderLightBoxContent()}
            <img
              className='mars-mission-intro__light-box-close-btn'
              src={closeBtn}
              alt=''
              onClick={(e) => {
                e.preventDefault();
                closeLightbox();
              }}
              onTouchEnd={(e) => {
                e.preventDefault();
                closeLightbox();
              }}
            />
          </div>
        </div>
      </div>

      <div className='mars-mission-intro__static-mars-container mars-mission-intro__static-mars-container-ani'>
        <img alt='' src={staticMars} className={
          classNames("mars-mission-intro__static-mars-1", "visible")
        } />
      </div>
      {(year === 2007 && !lightboxEnabled && <div className={classNames(
        'mars-mission-intro__ce2-video',
        'mars-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission1OverlayVideo} />
        </video>
      </div>)}
      {(year === 2010 && !lightboxEnabled && <div className={classNames(
        'mars-mission-intro__ce2-video',
        'mars-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission2OverlayVideo} />
        </video>
      </div>)}
      {(year === 2013 && !lightboxEnabled && <div className={classNames(
        'mars-mission-intro__ce3-video',
        'mars-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission3OverlayVideo} />
        </video>
      </div>)}
      {(year === 2014 && !lightboxEnabled  && <div className={classNames(
        'mars-mission-intro__ce4-video',
        'mars-mission-intro__ce-overlay-video',
        "showAnimationWithDelay",
        {
          "fadOut": navigateToNext
        }
      )}>
        <video muted={true}  loop={true} autoPlay={true} preload='auto'>
          <source src={mission4OverlayVideo} />
        </video>
      </div>)}
    </>
  )
}

export default MarsMissionIntro
