import { useEffect, useState } from "react"
import pad from "pad";
import CircularSeekBar from '../../components/circular-seekbar';
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Lottie from "react-lottie";

import * as moonDL1 from '../../lottie/moon-rover/IndicLines_AN1.json';
import * as moonDL2 from '../../lottie/moon-rover/IndicLines_AN2.json';
import * as moonDL3 from '../../lottie/moon-rover/IndicLines_AN3.json';
import * as moonDL4 from '../../lottie/moon-rover/IndicLines_AN4.json';
import * as moonDL5 from '../../lottie/moon-rover/IndicLines_AN5.json';
import * as moonDL6 from '../../lottie/moon-rover/IndicLines_AN6.json';
import * as moonDL7 from '../../lottie/moon-rover/IndicLines_AN7.json';
import * as moonDL8 from '../../lottie/moon-rover/IndicLines_AN8.json';
import * as moonDL9 from '../../lottie/moon-rover/IndicLines_AN9.json';
import * as moonDL10 from '../../lottie/moon-rover/IndicLines_AN10.json';
import * as moonDL11 from '../../lottie/moon-rover/IndicLines_AN11.json';
import * as moonDL12 from '../../lottie/moon-rover/IndicLines_AN12.json';
import * as moonDL35 from '../../lottie/moon-rover/IndicLines_AN35.json';
import * as moonDL36 from '../../lottie/moon-rover/IndicLines_AN36.json';
import moonPageData from '../../json/moon-rover-data-point.json';
import classNames from "classnames";
import RoverButton from "../../components/rover-button";
import ScrollableViewWithFader from "../../components/scrollable-view-with-fader";

import lightBoxImg1 from '../../img/moon-rover/photo/1.jpg';
import lightBoxImg2 from '../../img/moon-rover/photo/2.jpg';
import lightBoxImg3 from '../../img/moon-rover/photo/3.jpg';
import lightBoxImg4 from '../../img/moon-rover/photo/4.jpg';
import lightBoxImg5 from '../../img/moon-rover/photo/5.jpg';
import lightBoxImg6 from '../../img/moon-rover/photo/6.jpg';
import lightBoxImg7 from '../../img/moon-rover/photo/7.jpg';
import lightBoxImg8 from '../../img/moon-rover/photo/8.jpg';
import lightBoxImg9 from '../../img/moon-rover/photo/9.jpg';
import lightBoxImg10 from '../../img/moon-rover/photo/10.jpg';
import lightBoxImg11 from '../../img/moon-rover/photo/11.jpg';
import { debounce, throttle } from "lodash";
import Const from "../../const/const";

const photos = {
  "1": lightBoxImg1,
  "2": lightBoxImg2,
  "3": lightBoxImg3,
  "4": lightBoxImg4,
  "5": lightBoxImg5,
  "6": lightBoxImg6,
  "7": lightBoxImg7,
  "8": lightBoxImg8,
  "9": lightBoxImg9,
  "10": lightBoxImg10,
  "11": lightBoxImg11
};

const lottieWidth = 2160;
const lottieHeight = 1500;

const MoonRover = () => {
  const { i18n } = useTranslation();

  const [intVal, setIntVal] = useState(1);
  // const [dotAndLineVal, setDotAndLineVal] = useState(1);
  const [showDotAndLine, setShowDotAndLine] = useState(true);
  const [showLightBox, setShowLightBox] = useState(false);
  const [lightBoxContent, setLightBoxContent] = useState(null);

  const tSetShowDotAndLine = throttle(setShowDotAndLine, 500);

  const LightBoxContent = ({ content }) => {
    const { i18n } = useTranslation();
    const isZh = (i18n.language === 'zh');

    useEffect(() => {
      document.querySelector('.customscroll').scrollTop = 0;
      // eslint-disable-next-line
    }, [lightBoxContent]);

    return (
      <div className={
        classNames(
          `moon-rover__lightbox-type-${content['type']}`
        )
      }>
        {
          (photos[content['id']]) &&
          <img className="moon-rover__lightbox-image" src={photos[content['id']]} alt='' />
        }
        <div className="moon-rover__lightbox-photo-caption">{(isZh) ? content['caption_zh'] : content['caption_en']}</div>
        <div className="moon-rover__lightbox-title-row">
          <div className="moon-rover__lightbox-title-left-pane">
            <div className="moon-rover__lightbox-title">{(isZh) ? content['title_zh'] : content['title_en']}</div>
            <div className="moon-rover__lightbox-desc-container">
              <div className="moon-rover__lightbox-pre-dash"></div>
              <div className="moon-rover__lightbox-desc">{(isZh) ? content['desc_zh'] : content['desc_en']}</div>
            </div>
          </div>
          <div className="moon-rover__lightbox-title-type-pane">
            <div className="moon-rover__lightbox-class-dot"></div>
            <div>{
              (content['type'] === "sp") ? t('moonRover.science-payload') : t('moonRover.general')
            }</div>
          </div>
        </div>
        <div className={
          classNames(
            "moon-rover__lightbox-bullets-container",
            {
              "moon-rover__lightbox-bullets-container-zh": isZh,
              "moon-rover__lightbox-bullets-container-en": !isZh
            }
          )
        }>
          <ul>
            {
              (((isZh) ? content['bullets_zh'] : content['bullets_en']).map((v) => {
                return <li>{v}</li>
              }))
            }
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="moon-rover showAnimation">
        <div className={
          classNames(
            "moon-rover__rover-title",
            {
              "moon-rover__rover-title-en": (i18n.language === 'en')
            }
          )
        }></div>
        <div className="moon-rover__shadow-bg"></div>
        {/* {(!showLightBox && <div className={
          classNames(
            "moon-rover__rover-instruction",
            {
              "moon-rover__rover-instruction-en": (i18n.language === 'en')
            }
          )
        }>{t('moonRover.instruction')}</div>)} */}
        {(!showLightBox &&
          <>
            <div className={
              classNames(
                "moon-rover__rover-instruction",
                {
                  "moon-rover__rover-instruction-en": (i18n.language === 'en')
                }
              )
            }>
              {t('moonRover.instruction')}
              <RoverButton className={"moon-rover__rover-button-inline"} />
              {t('moonRover.instruction2')}
            </div>
          </>
        )}
        <div className={"moon-rover__rover-img-seq"}>
          <img src={`${process.env.PUBLIC_URL}/moon-rover/yutu.${pad(4, intVal, '0')}.png`} alt="" />
        </div>
        <div className="moon-rover__seek-bar">
          <CircularSeekBar onChange={(value) => {
            var newVal = parseInt(value) + 1;
            setIntVal(newVal);
            setShowDotAndLine(false);
          }} onSettled={(_) => {
            tSetShowDotAndLine(true);
          }}  />
        </div>
      </div>
      {(intVal !== 0 && showDotAndLine) ? <DottedLineSceneContainer index={intVal} /> : <></>}
      {(intVal !== 0 && showDotAndLine) ? <LottieDotButtonContainer index={intVal} onClick={(index) => {
        console.log(index);
        setLightBoxContent(moonPageData['points'][index.toString()]);
        setShowLightBox(true);
      }} /> : <></>}
      <div className={
        classNames(
          "moon-rover__lightbox-outer",
          {
            "visible": showLightBox
          }
        )}>
        <div className={
          classNames(
            "moon-rover__lightbox-container",
            "moon-rover__lightbox-share-prop",
            {
              "moon-rover__lightbox-container-en": (i18n.language === 'en'),
              "moon-rover__lightbox-container-zh": (i18n.language === 'zh')
            }
          )
        }>
          <div className="moon-rover__lightbox-inner-dark-container moon-rover__lightbox-share-prop"></div>
          <div className="moon-rover__lightbox-inner-container moon-rover__lightbox-share-prop"></div>
          <div className="moon-rover__lightbox-border-container moon-rover__lightbox-share-prop"></div>
          <ScrollableViewWithFader className="moon-rover__lightbox-scroll-view">
            {(lightBoxContent) && <LightBoxContent content={lightBoxContent} />}
          </ScrollableViewWithFader>
          <div className="moon-rover__lightbox-close-btn" onClick={(e) => {
            e.preventDefault();
            setShowLightBox(false);
          }} 
          onTouchEnd={(e) => {
            e.preventDefault();
            setShowLightBox(false);
          }}></div>
        </div>

      </div>
    </>
  )
}

const LottieDotButtonContainer = (({ index, onClick }) => {
  const arr = moonPageData['dotted'][index.toString()];

  const dOnClick = debounce(onClick, Const.debounceValue);
  return (
    <div className="moon-rover__button-lottie-container">
      {arr && arr.map((v) => <div className={classNames(
        "moon-rover__rover-button-container",
        `moon-rover__rover-button-container-id-${v['pointId']}`
      )} key={`${index}-${v['pointId']}`}
        style={{
          top: `${v['y']}px`,
          left: `${v['x']}px`
        }}
        onClick={() => {
          dOnClick(v['pointId'])
        }} 
        onTouchEnd={() => {
          dOnClick(v['pointId'])
        }} >
        <RoverButton className={"moon-rover__rover-button"} />
      </div>)}
    </div>
  )
});

const DottedLineSceneContainer = (({ index }) => {
  return (
    <div className={
      classNames(
        "moon-rover__dotted-line-lottie-container",
        `moon-rover__dotted-line-lottie-container-scene-${index}`
      )
    }>
      <DottedLineScene index={index} />
    </div>
  )
});

const DottedLineScene = (({ index }) => {
  return (
    <div className={classNames(`moon-rover__dotted-line-scene-${index}`)}>
      <Lottie
        options={getLottieOption({ index })}
        width={lottieWidth}
        height={lottieHeight}
      />
    </div>
  );
});


function getLottieOption({ index }) {
  return {
    loop: false,
    autoplay: true,
    animationData: getLottieJSON({ index }),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
}

function getLottieJSON({ index }) {
  switch (index) {
    case 1:
      return moonDL1;
    case 2:
      return moonDL2;
    case 3:
      return moonDL3;
    case 4:
      return moonDL4;
    case 5:
      return moonDL5;
    case 6:
      return moonDL6;
    case 7:
      return moonDL7;
    case 8:
      return moonDL8;
    case 9:
      return moonDL9;
    case 10:
      return moonDL10;
    case 11:
      return moonDL11;
    case 12:
      return moonDL12;
    case 35:
      return moonDL35;
    case 36:
      return moonDL36;
    default:
      return null;
  }
}

export default MoonRover
