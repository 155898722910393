import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './i18n';
import './styles/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
const useScaleDown = window.electronAPI?.useScaleDown ?? (process.env.REACT_APP_SCALE ? true : false)
const width = `${(window.electronAPI?.width) ?? 2160}px`
const height = `${(window.electronAPI?.height) ?? 3840}px`
const scaleDownScale = window.electronAPI?.scale ?? (process.env.REACT_APP_SCALE ?? 1)


console.log("useScaleDown", useScaleDown)
console.log("scaleDownScale", scaleDownScale)
const styles = {
  scaleContainer: {
    transform: useScaleDown ? `scale(${(1 / scaleDownScale)})` : 'scale(1.0)',
    transformOrigin: 'top left',
    overflow: 'hidden',
    width,
    height,
  },
  appStyle: {
    width: width,
    height: height
  }
}

root.render(
  <div style={styles.scaleContainer}>
    <App style={styles.appStyle} />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
