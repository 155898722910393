import { useEffect, useState } from 'react';

import headingImg from '../../img/moon-menu/heading.png';
import headingEnImg from '../../img/moon-menu/heading-en.png';
import { useTranslation } from 'react-i18next';
import { useTravel } from '../../context/travelContext';
import classNames from 'classnames';
import MoonMenuLottieButton from '../../components/moon-menu-lottie-button';
import { debounce } from 'lodash';
import Const from '../../const/const';

const pageRouteName = '/moon-menu'

const MoonMenu = () => {
  const { i18n } = useTranslation();
  const { travelValue, travelTo } = useTravel();
  const [ navigateToNext, setNavigateToNext ] = useState(false);

  const go = (where) => {
    travelTo({value:where});
  }

  const dGo = debounce(go, Const.debounceValue);

  useEffect(() => {
    if (travelValue !== pageRouteName) {
      setNavigateToNext(true);
    } else {
      setNavigateToNext(false);
    }
  }, [travelValue])

  return (
    <>
      <div className={
        classNames(
          "moon-menu__color-bar", 
          "showAnimation",
          {
            "fadeOut": navigateToNext
          }
        )
      }></div>
      <div className={classNames("moon-menu__moon-shadow","showAnimation",
      {
        "fadeOut": navigateToNext
      }
      )}></div>
      <div className="moon-menu">
        <div className={classNames("moon-menu__heading","showAnimation",
          {
            "fadeOut": navigateToNext
          }
        )}>
          <img src={(i18n.language === 'en')?headingEnImg:headingImg} alt="" />
        </div>
        <div className="moon-menu__moon">
          
        </div>
        <div className="moon-menu__items">
          <div className={classNames("moon-menu__item","showAnimation", 
            {
              "fadeOut": navigateToNext
            }
          )}>
            <div className="moon-menu__items-items" onClick={() => dGo('/moon-mission')} onTouchEnd={() => dGo('/moon-mission')}>
              <MoonMenuLottieButton buttonLangKey={"moonMenu.moonMission"} />
            </div>
            <div className="moon-menu__items-items" onClick={() => dGo('/moon-vision')} onTouchEnd={() => dGo('/moon-vision')}>
              <MoonMenuLottieButton buttonLangKey={'moonMenu.moonVision'} />
            </div>
            <div className="moon-menu__items-items" onClick={() => dGo('/moon-rover')} onTouchEnd={() => dGo('/moon-rover')}>
              <MoonMenuLottieButton buttonLangKey={'moonMenu.moonRover'} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MoonMenu
