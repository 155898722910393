import Lottie from "react-lottie";
import * as lottieData from '../lottie/moon-rover/RoverButton.json';

const RoverButton = ({ className, onClick }) => {
    const lottieOption = {
        loop: true,
        autoplay: true,
        animationData: lottieData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    return (
        <>
            <div className={className}>
                <Lottie
                    options={lottieOption}
                    width={80}
                    height={80}
                    />
            </div>
        </>
    )
};

export default RoverButton;