import {
  useNavigate,
  useLocation,
  HashRouter,
  Routes,
  Route,
} from 'react-router-dom';

import { useIdleTimer } from './hooks/useIdleTimer';
import PreloadImages from './PreloadImages';
import IdleMenu from './pages/IdleMenu';
import MarsMenu from './pages/MarsMenu';
import MarsMission from './pages/MarsMission';
import MarsMissionIntro from './pages/MarsMissionIntro';
import MarsRover from './pages/MarsRover';
import MarsVision from './pages/MarsVision';
import MarsLinkWithHK from './pages/MarsLinkWithHK';
import MoonMenu from './pages/MoonMenu';
import MoonMission from './pages/MoonMission';
import MoonMissionIntro from './pages/MoonMissionIntro';
import MoonRover from './pages/MoonRover';
import MoonVision from './pages/MoonVision';
import MoonLinkWithHK from './pages/MoonLinkWithHK';
import PageLayout from './layout/PageLayout';
import MoonPhotoGallery from './pages/MoonPhotoGallery';
import MarsPhotoGallery from './pages/MarsPhotoGallery';
import { LightboxProvider } from './context/lightboxContext';
import { TravelProvider } from './context/travelContext';

const IdleTimerComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const timeout = process.env.REACT_APP_IDLE_TIMEOUT;

  const handleIdle = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  useIdleTimer(timeout, handleIdle);

  return null;
};

function App() {
  return (
    <HashRouter>
      <IdleTimerComponent />
      <PreloadImages />
      <TravelProvider>
        <LightboxProvider>
          <PageLayout>
            <Routes>
              <Route path="/" element={<IdleMenu />} />
              <Route path="/mars-menu" element={<MarsMenu />} />
              <Route path="/mars-mission" element={<MarsMission />} />
              <Route path="/mars-mission-intro" element={<MarsMissionIntro />} />
              <Route path="/mars-rover" element={<MarsRover />} />
              <Route path="/mars-vision" element={<MarsVision />} />
              <Route path="/mars-link-with-hk" element={<MarsLinkWithHK />} />
              <Route path="/mars-photo-gallary" element={<MarsPhotoGallery />} />
              <Route path="/moon-menu" element={<MoonMenu />} />
              <Route path="/moon-mission" element={<MoonMission />} />
              <Route path="/moon-mission-intro" element={<MoonMissionIntro />} />
              <Route path="/moon-rover" element={<MoonRover />} />
              <Route path="/moon-vision" element={<MoonVision />} />
              <Route path="/moon-link-with-hk" element={<MoonLinkWithHK />} />
              <Route path="/moon-photo-gallary" element={<MoonPhotoGallery />} />
            </Routes>
          </PageLayout>
        </LightboxProvider>
      </TravelProvider>
    </HashRouter>
  );
}

export default App;
