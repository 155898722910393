import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CeInlineButton from '../../components/ce-inline-button';
import ScrollableViewWithFader from '../../components/scrollable-view-with-fader';

import closeBtn from '../../img/mars-mission-intro/close-btn.png';
import moonLinkWithHKLightboxImg1 from '../../img/moon-link-with-hk/lightbox-img-1.jpg';
import moonLinkWithHKLightboxImg2 from '../../img/moon-link-with-hk/lightbox-img-2.jpg';
import moonLinkWithHKLightboxImg3 from '../../img/moon-link-with-hk/lightbox-img-3.jpg';
import moonLinkWithHKLightboxImg4 from '../../img/moon-link-with-hk/lightbox-img-4.jpg';
import moonLinkWithHKLightboxImg5 from '../../img/moon-link-with-hk/lightbox-img-5.jpg';
import classNames from 'classnames';
import { useLightbox } from '../../context/lightboxContext';

const RenderLightBoxContent = ({ index }) => {
	const { t, i18n } = useTranslation();

	switch (index) {
		case 1:
			return (
				<div className={
					classNames(
						"moon-link-with-hk__lightbox-box-container",
						{
							"moon-link-with-hk__lightbox-box-container-en": (i18n.language === 'en')
						}
					)}>
					<Trans
						i18nKey="moonLinkWithHK.tag1.content"
						components={[
							<img src={moonLinkWithHKLightboxImg1} alt='' />,
							<img src={moonLinkWithHKLightboxImg2} alt='' />,
							<div className="moon-link-with-hk__lightbox-title" />,
							<div className="moon-link-with-hk__lightbox-pic-source" />,
							<div className="moon-link-with-hk__lightbox-pic-desc" />,
							<p className="moon-link-with-hk__lightbox-p" />
						]} />
				</div>
			)
		case 2:
			return (
				<div className={
					classNames(
						"moon-link-with-hk__lightbox-box-container",
						{
							"moon-link-with-hk__lightbox-box-container-en": (i18n.language === 'en')
						}
					)}>
					<Trans
						i18nKey="moonLinkWithHK.tag2.content"
						components={[
							<img src={moonLinkWithHKLightboxImg3} alt='' />,
							<img src={moonLinkWithHKLightboxImg2} alt='' />,
							<div className="moon-link-with-hk__lightbox-title" />,
							<div className="moon-link-with-hk__lightbox-pic-source" />,
							<div className="moon-link-with-hk__lightbox-pic-desc" />,
							<p className="moon-link-with-hk__lightbox-p" />
						]} />
				</div>
			)
		case 3:
			return (
				<div className={
					classNames(
						"moon-link-with-hk__lightbox-box-container",
						{
							"moon-link-with-hk__lightbox-box-container-en": (i18n.language === 'en')
						}
					)}>
					<div className="moon-link-with-hk__lightbox-title">{t('moonLinkWithHK.tag3.label')}</div>
					<Trans
						i18nKey="moonLinkWithHK.tag3.content"
						components={[
							<img src={moonLinkWithHKLightboxImg4} alt='' />,
							<></>,
							<div className="moon-link-with-hk__lightbox-title" />,
							<div className="moon-link-with-hk__lightbox-pic-source" />,
							<div className="moon-link-with-hk__lightbox-pic-desc" />,
							<p className="moon-link-with-hk__lightbox-p" />
						]} />
					<img src={moonLinkWithHKLightboxImg4} alt='' />
					<div>
						<span className="moon-link-with-hk__lightbox-pic-source2">{t('moonLinkWithHK.tag3.content4')}</span>
						<span className="moon-link-with-hk__lightbox-pic-source">{t('moonLinkWithHK.tag3.content2')}</span>
					</div>
					<p></p>
					<img src={moonLinkWithHKLightboxImg5} alt='' />
					<div>
						<span className="moon-link-with-hk__lightbox-pic-source2">{t('moonLinkWithHK.tag3.content3')}</span>
						<span className="moon-link-with-hk__lightbox-pic-source">{t('moonLinkWithHK.tag3.content2')}</span>
					</div>
				</div>
			)
		default:
			return <></>;
	}
}

const MoonLinkWithHK = () => {
	const [currentTab, setCurrentTab] = useState(1);
	const { i18n, t } = useTranslation();
	const [lightBoxIndex, setLightBoxIndex] = useState(null);
	const { enabledLightbox, disableLightbox } = useLightbox();
	const [activeTab, setActiveTab] = useState(1);

	return (
		<>
			<div className='moon-global__right-deco showAnimation'></div>
			<div className="moon-link-with-hk">
				<div className="moon-link-with-hk__container showAnimation">
					<div className={i18n.language === 'en' ? "moon-link-with-hk__title-en" : "moon-link-with-hk__title"} />
				</div>
				<div className={classNames(
					"moon-link-with-hk__text-box-container",
					"showAnimation",
					{
						"moon-link-with-hk__text-box-container-en": (i18n.language === 'en')
					}
				)}>
					<ScrollableViewWithFader className="moon-link-with-hk__text-content"
						anchorPoint={currentTab}
						onScrollOverTag={(n) => {
							console.log(n);
							setActiveTab(n + 1);
						}}>
						{ /* eslint-disable-next-line */}
						<a id="tab1" />
						<Trans
							i18nKey="moonLinkWithHK.content"
							components={[
								<p />,
								<p className="moon-link-with-hk__mini-title" />,
								// eslint-disable-next-line
								<a id="tab2" />,
								// eslint-disable-next-line
								<a id="tab3" />
							]}
						/>
						<div className="moon-link-with-hk__tags">
							<CeInlineButton languageKey={t("moonLinkWithHK.tag1.label")} onClick={() => {
								enabledLightbox();
								setLightBoxIndex(1)
							}} />
							<CeInlineButton languageKey={t("moonLinkWithHK.tag2.label")} onClick={() => {
								enabledLightbox();
								setLightBoxIndex(2)
							}} />
							<CeInlineButton languageKey={t("moonLinkWithHK.tag3.label")} onClick={() => {
								enabledLightbox();
								setLightBoxIndex(3)
							}} />
						</div>
						<div className='moon-link-with-hk__text-content-padder'></div>
					</ScrollableViewWithFader>
				</div>
				{(lightBoxIndex) ?
					(<div className={
						classNames("moon-link-with-hk__light-box-container showAnimation")}>
						<RenderLightBoxContent index={lightBoxIndex} />
						<img
							className='moon-link-with-hk__light-box-close-btn'
							src={closeBtn}
							onClick={(e) => {
								e.preventDefault();
								disableLightbox();
								setLightBoxIndex(null);
							}}
							onTouchEnd={(e) => {
								e.preventDefault();
								disableLightbox();
								setLightBoxIndex(null);
							}}
							alt=""
						/>
					</div>) : <></>}
			</div>
			<div className="moon-link-with-hk__tab-box-container">
				<div className={
					classNames(
						"moon-link-with-hk__tab-box-content",
						{
							"moon-link-with-hk__tab-box-content-en": (i18n.language === 'en')
						}
					)
				}>
					<div
						className={classNames('moon-link-with-hk__tab-box-tab', { 'active': activeTab === 1 })}
						onClick={() => setCurrentTab(1)}
						onTouchEnd={() => setCurrentTab(1)}
					>
						{t('moonLinkWithHK.tabs.tab1')}
					</div>
					<div
						className={classNames('moon-link-with-hk__tab-box-tab', { 'active': activeTab === 2 })}
						onClick={() => setCurrentTab(2)}
						onTouchEnd={() => setCurrentTab(2)}
					>
						{t('moonLinkWithHK.tabs.tab2')}
					</div>
					<div
						className={classNames('moon-link-with-hk__tab-box-tab', { 'active': activeTab === 3 })}
						onClick={() => setCurrentTab(3)}
						onTouchEnd={() => setCurrentTab(3)}
					>
						{t('moonLinkWithHK.tabs.tab3')}
					</div>
				</div>
			</div>
		</>
	)
}

export default MoonLinkWithHK
