import { useTranslation } from "react-i18next";

const DivTsByLocale = ({languageKey}) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={ "div-ts-by-locale " + ((i18n.language === 'zh')? "" : "div-ts-by-locale__en") }>
            {t(languageKey)}
        </div>
    )
};

export default DivTsByLocale;