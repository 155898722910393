import { useEffect, useState } from 'react';
import closeBtn from '../../img/mars-mission-intro/close-btn.png';

import gridImg1 from '../../img/mars-photo-gallery/grid/grid_01.jpg';
import gridImg2 from '../../img/mars-photo-gallery/grid/grid_02.jpg';
import gridImg3 from '../../img/mars-photo-gallery/grid/grid_03.jpg';
import gridImg4 from '../../img/mars-photo-gallery/grid/grid_04.jpg';
import gridImg5 from '../../img/mars-photo-gallery/grid/grid_05.jpg';
import gridImg6 from '../../img/mars-photo-gallery/grid/grid_06.jpg';
import gridImg7 from '../../img/mars-photo-gallery/grid/grid_07.jpg';
import gridImg8 from '../../img/mars-photo-gallery/grid/grid_08.jpg';
import gridImg9 from '../../img/mars-photo-gallery/grid/grid_09.jpg';
import gridImg10 from '../../img/mars-photo-gallery/grid/grid_10.jpg';
import gridImg11 from '../../img/mars-photo-gallery/grid/grid_11.jpg';
import gridImg12 from '../../img/mars-photo-gallery/grid/grid_12.jpg';
import gridImg13 from '../../img/mars-photo-gallery/grid/grid_13.jpg';
import gridImg14 from '../../img/mars-photo-gallery/grid/grid_14.jpg';
import gridImg15 from '../../img/mars-photo-gallery/grid/grid_15.jpg';
import gridImg16 from '../../img/mars-photo-gallery/grid/grid_16.jpg';
import gridImg17 from '../../img/mars-photo-gallery/grid/grid_17.jpg';
import gridImg18 from '../../img/mars-photo-gallery/grid/grid_18.jpg';
import gridImg19 from '../../img/mars-photo-gallery/grid/grid_19.jpg';
import gridImg20 from '../../img/mars-photo-gallery/grid/grid_20.jpg';
import gridImg21 from '../../img/mars-photo-gallery/grid/grid_21.jpg';
import gridImg22 from '../../img/mars-photo-gallery/grid/grid_22.jpg';
import gridImg23 from '../../img/mars-photo-gallery/grid/grid_23.jpg';
import gridImg24 from '../../img/mars-photo-gallery/grid/grid_24.jpg';
import gridImg25 from '../../img/mars-photo-gallery/grid/grid_25.jpg';
import gridImg26 from '../../img/mars-photo-gallery/grid/grid_26.jpg';
import gridImg27 from '../../img/mars-photo-gallery/grid/grid_27.jpg';
import gridImg28 from '../../img/mars-photo-gallery/grid/grid_28.jpg';
import gridImg29 from '../../img/mars-photo-gallery/grid/grid_29.jpg';
import gridImg30 from '../../img/mars-photo-gallery/grid/grid_30.jpg';
import gridImg31 from '../../img/mars-photo-gallery/grid/grid_31.jpg';
import gridImg32 from '../../img/mars-photo-gallery/grid/grid_32.jpg';
import gridImg33 from '../../img/mars-photo-gallery/grid/grid_33.jpg';
import gridImg34 from '../../img/mars-photo-gallery/grid/grid_34.jpg';
import gridImg35 from '../../img/mars-photo-gallery/grid/grid_35.jpg';
import gridImg36 from '../../img/mars-photo-gallery/grid/grid_36.jpg';
import gridImg37 from '../../img/mars-photo-gallery/grid/grid_37.jpg';
import gridImg38 from '../../img/mars-photo-gallery/grid/grid_38.jpg';
import gridImg39 from '../../img/mars-photo-gallery/grid/grid_39.jpg';
import gridImg40 from '../../img/mars-photo-gallery/grid/grid_40.jpg';
import gridImg41 from '../../img/mars-photo-gallery/grid/grid_41.jpg';
import gridImg42 from '../../img/mars-photo-gallery/grid/grid_42.jpg';
import gridImg43 from '../../img/mars-photo-gallery/grid/grid_43.jpg';
import gridImg44 from '../../img/mars-photo-gallery/grid/grid_44.jpg';
import gridImg45 from '../../img/mars-photo-gallery/grid/grid_45.jpg';
import gridImg46 from '../../img/mars-photo-gallery/grid/grid_46.jpg';
import gridImg47 from '../../img/mars-photo-gallery/grid/grid_47.jpg';
import gridImg48 from '../../img/mars-photo-gallery/grid/grid_48.jpg';
import gridImg49 from '../../img/mars-photo-gallery/grid/grid_49.jpg';
import gridImg50 from '../../img/mars-photo-gallery/grid/grid_50.jpg';
import gridImg51 from '../../img/mars-photo-gallery/grid/grid_51.jpg';
import gridImg52 from '../../img/mars-photo-gallery/grid/grid_52.jpg';
import gridImg53 from '../../img/mars-photo-gallery/grid/grid_53.jpg';
import gridImg54 from '../../img/mars-photo-gallery/grid/grid_54.jpg';
import gridImg55 from '../../img/mars-photo-gallery/grid/grid_55.jpg';
import gridImg56 from '../../img/mars-photo-gallery/grid/grid_56.jpg';
import gridImg57 from '../../img/mars-photo-gallery/grid/grid_57.jpg';
import gridImg58 from '../../img/mars-photo-gallery/grid/grid_58.jpg';
import gridImg59 from '../../img/mars-photo-gallery/grid/grid_59.jpg';
import gridImg60 from '../../img/mars-photo-gallery/grid/grid_60.jpg';
import gridImg61 from '../../img/mars-photo-gallery/grid/grid_61.jpg';
import gridImg62 from '../../img/mars-photo-gallery/grid/grid_62.jpg';
import gridImg63 from '../../img/mars-photo-gallery/grid/grid_63.jpg';
import gridImg64 from '../../img/mars-photo-gallery/grid/grid_64.jpg';
import gridImg65 from '../../img/mars-photo-gallery/grid/grid_65.jpg';
import gridImg66 from '../../img/mars-photo-gallery/grid/grid_66.jpg';
import gridImg67 from '../../img/mars-photo-gallery/grid/grid_67.jpg';
import gridImg68 from '../../img/mars-photo-gallery/grid/grid_68.jpg';
import gridImg69 from '../../img/mars-photo-gallery/grid/grid_69.jpg';
import gridImg70 from '../../img/mars-photo-gallery/grid/grid_70.jpg';
import gridImg71 from '../../img/mars-photo-gallery/grid/grid_71.jpg';
import gridImg72 from '../../img/mars-photo-gallery/grid/grid_72.jpg';
import gridImg73 from '../../img/mars-photo-gallery/grid/grid_73.jpg';
import gridImg74 from '../../img/mars-photo-gallery/grid/grid_74.jpg';
import gridImg75 from '../../img/mars-photo-gallery/grid/grid_75.jpg';
import gridImg76 from '../../img/mars-photo-gallery/grid/grid_76.jpg';
import gridImg77 from '../../img/mars-photo-gallery/grid/grid_77.jpg';
import gridImg78 from '../../img/mars-photo-gallery/grid/grid_78.jpg';
import gridImg79 from '../../img/mars-photo-gallery/grid/grid_79.jpg';
import gridImg80 from '../../img/mars-photo-gallery/grid/grid_80.jpg';
import gridImg81 from '../../img/mars-photo-gallery/grid/grid_81.jpg';
import gridImg82 from '../../img/mars-photo-gallery/grid/grid_82.jpg';
import gridImg83 from '../../img/mars-photo-gallery/grid/grid_83.jpg';
import gridImg84 from '../../img/mars-photo-gallery/grid/grid_84.jpg';
import gridImg85 from '../../img/mars-photo-gallery/grid/grid_85.jpg';
import gridImg86 from '../../img/mars-photo-gallery/grid/grid_86.jpg';
import gridImg87 from '../../img/mars-photo-gallery/grid/grid_87.jpg';
import gridImg88 from '../../img/mars-photo-gallery/grid/grid_88.jpg';
import gridImg89 from '../../img/mars-photo-gallery/grid/grid_89.jpg';
import gridImg90 from '../../img/mars-photo-gallery/grid/grid_90.jpg';
import gridImg91 from '../../img/mars-photo-gallery/grid/grid_91.jpg';
import gridImg92 from '../../img/mars-photo-gallery/grid/grid_92.jpg';
import gridImg93 from '../../img/mars-photo-gallery/grid/grid_93.jpg';
import gridImg94 from '../../img/mars-photo-gallery/grid/grid_94.jpg';
import gridImg95 from '../../img/mars-photo-gallery/grid/grid_95.jpg';
import gridImg96 from '../../img/mars-photo-gallery/grid/grid_96.jpg';
import gridImg97 from '../../img/mars-photo-gallery/grid/grid_97.jpg';
import gridImg98 from '../../img/mars-photo-gallery/grid/grid_98.jpg';
import gridImg99 from '../../img/mars-photo-gallery/grid/grid_99.jpg';
import gridImg100 from '../../img/mars-photo-gallery/grid/grid_100.jpg';
import gridImg101 from '../../img/mars-photo-gallery/grid/grid_101.jpg';
import gridImg102 from '../../img/mars-photo-gallery/grid/grid_102.jpg';
import gridImg103 from '../../img/mars-photo-gallery/grid/grid_103.jpg';
import gridImg104 from '../../img/mars-photo-gallery/grid/grid_104.jpg';
import gridImg105 from '../../img/mars-photo-gallery/grid/grid_105.jpg';
import gridImg106 from '../../img/mars-photo-gallery/grid/grid_106.jpg';
import gridImg107 from '../../img/mars-photo-gallery/grid/grid_107.jpg';
import gridImg108 from '../../img/mars-photo-gallery/grid/grid_108.jpg';
import gridImg109 from '../../img/mars-photo-gallery/grid/grid_109.jpg';
import gridImg110 from '../../img/mars-photo-gallery/grid/grid_110.jpg';
import gridImg111 from '../../img/mars-photo-gallery/grid/grid_111.jpg';
import gridImg112 from '../../img/mars-photo-gallery/grid/grid_112.jpg';
import gridImg113 from '../../img/mars-photo-gallery/grid/grid_113.jpg';
import gridImg114 from '../../img/mars-photo-gallery/grid/grid_114.jpg';
import gridImg115 from '../../img/mars-photo-gallery/grid/grid_115.jpg';
import gridImg116 from '../../img/mars-photo-gallery/grid/grid_116.jpg';
import gridImg117 from '../../img/mars-photo-gallery/grid/grid_117.jpg';
import gridImg118 from '../../img/mars-photo-gallery/grid/grid_118.jpg';
import gridImg119 from '../../img/mars-photo-gallery/grid/grid_119.jpg';
import gridImg120 from '../../img/mars-photo-gallery/grid/grid_120.jpg';
import gridImg121 from '../../img/mars-photo-gallery/grid/grid_121.jpg';
import filter from '../../img/mars-photo-gallery/filter.png';

import photoImg1 from '../../img/mars-photo-gallery/photo/1.jpg';
import photoImg2 from '../../img/mars-photo-gallery/photo/2.jpg';
import photoImg3 from '../../img/mars-photo-gallery/photo/3.jpg';
import photoImg4 from '../../img/mars-photo-gallery/photo/4.jpg';
import photoImg5 from '../../img/mars-photo-gallery/photo/5.jpg';
import photoImg6 from '../../img/mars-photo-gallery/photo/6.jpg';
import photoImg7 from '../../img/mars-photo-gallery/photo/7.jpg';
import photoImg8 from '../../img/mars-photo-gallery/photo/8.jpg';
import photoImg9 from '../../img/mars-photo-gallery/photo/9.jpg';
import photoImg10 from '../../img/mars-photo-gallery/photo/10.jpg';
import photoImg11 from '../../img/mars-photo-gallery/photo/11.jpg';
import photoImg12 from '../../img/mars-photo-gallery/photo/12.jpg';
import photoImg13 from '../../img/mars-photo-gallery/photo/13.jpg';
import photoImg14 from '../../img/mars-photo-gallery/photo/14.jpg';
import photoImg15 from '../../img/mars-photo-gallery/photo/15.jpg';
import photoImg16 from '../../img/mars-photo-gallery/photo/16.jpg';
import photoImg17 from '../../img/mars-photo-gallery/photo/17.jpg';
import photoImg18 from '../../img/mars-photo-gallery/photo/18.jpg';
import photoImg19 from '../../img/mars-photo-gallery/photo/19.jpg';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import photoData from '../../json/mars-photo-gallery-data.json';
import { useLightbox } from '../../context/lightboxContext';

//transform: translateX(-800px) translateY(-800px);
const DEFAULT_TRANSLATE_X = -1293;
const DEFAULT_TRANSLATE_Y = -1224;

const scaleDownScale = window.electronAPI?.scale ?? 1

const imgs = [
    photoImg1,photoImg2,photoImg3,photoImg4,photoImg5,photoImg6,photoImg7,photoImg8,photoImg9,photoImg10,photoImg11,photoImg12,photoImg13,photoImg14,photoImg15,photoImg16,photoImg17,photoImg18,photoImg19
];

const MarsPhotoGallery = () => {
    const { i18n } = useTranslation();

    const { enabledLightbox, disableLightbox } = useLightbox();

    const [lightBoxImage, setLightBoxImage] = useState(null);
    const [lightBoxDesc, setLightBoxDesc] = useState("");
    const [lightBoxDescEn, setLightBoxDescEn] = useState("");
    const [showLightBox, setShowLightBox] = useState(false);

    return (
        <div className="mars-photo-gallery showAnimation">
            <div className={classNames("mars-photo-gallery__title", {
                "mars-photo-gallery__title-en": i18n.language === "en"
            })}></div>
            <PhotoGalleryMap  onImageClick={(e) => {
                console.log("root", e);
                enabledLightbox();
                setLightBoxImage(imgs[(e.id - 1)]);
                setLightBoxDescEn(e['caption_en']);
                setLightBoxDesc(e['caption_zh']);
                setShowLightBox(true);
            }} lightBoxShowing={showLightBox} />
            <div className='mars-photo-gallery__filter'>
                <img src={filter} alt='' />
            </div>
            <div className={
                classNames(
                    "moon-photo-gallery__credit",
                    {
                        "moon-photo-gallery__credit-zh": (i18n.language === 'zh'),
                        "moon-photo-gallery__credit-en": (i18n.language === 'en')
                    }
                )
            }></div>
            <div className={classNames(
                'moon-photo-gallery__click-hint-container',
                {
                    'moon-photo-gallery__click-hint-container-zh' :  (i18n.language === 'zh'),
                    'moon-photo-gallery__click-hint-container-en' :  (i18n.language === 'en')
                }
            )}></div>
            <div className={"mars-photo-gallery__light-box " + ((showLightBox) ? 'visible' : '')}>
                {
                    (showLightBox) ?
                        <div className="mars-photo-gallery__light-box-content">
                            <img src={lightBoxImage} alt="" />
                            <div className={
                                classNames(
                                    "mars-photo-gallery__light-box-desc",
                                    {
                                        "mars-photo-gallery__light-box-desc-en": (i18n.language === "en"),
                                        "mars-photo-gallery__light-box-desc-zh": (i18n.language === "zh")
                                    }
                                )
                            }
                            >{(i18n.language === "en")?lightBoxDescEn:lightBoxDesc}</div>
                            <img src={closeBtn} className="mars-photo-gallery__light-box-close-btn" onClick={(e) => {
                                e.preventDefault();
                                disableLightbox();
                                setShowLightBox(false);
                            }} onTouchEnd={(e) => {
                                e.preventDefault();
                                disableLightbox();
                                setShowLightBox(false);
                            }} alt="" />      
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    )
};

const PhotoGalleryMap = ({ onImageClick, lightBoxShowing }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: DEFAULT_TRANSLATE_X, y: DEFAULT_TRANSLATE_Y });
    const [startPos, setStartPos] = useState({ startX: 0, startY: 0 });
    const [dragged, setDragged] = useState(false); // Flag to prevent click after drag

    // Handle mouse and touch start
    const handleStart = (e) => {

        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;
        
        setIsDragging(true);
        setDragged(false); // Reset the dragged state
        setStartPos({
        startX: (clientX * scaleDownScale) - position.x,
        startY: (clientY * scaleDownScale) - position.y,
        });
    };

    useEffect(() => {
        let interval
        if (!isDragging && !lightBoxShowing) {
            tick();
            interval = setInterval(() => {
                tick();
            }, 200);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isDragging, lightBoxShowing]);

    const tick = () => {
        setPosition((o) => { 
            return {
                "x": Math.max(-8540, Math.min(0, ((o.x - 10)))), 
                "y":  Math.max(-4489, Math.min(0, ((o.y - 10))))
            }
        });
    }
    
    // Handle mouse and touch move
    const handleMove = (e) => {
        if (!isDragging) return;

        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;

        // const newX = clientX - startPos.startX;
        // const newY = clientY - startPos.startY;

        const newX = Math.max(-8540, Math.min(0, ((clientX * scaleDownScale) - startPos.startX)));
        const newY = Math.max(-4489, Math.min(0, ((clientY * scaleDownScale) - startPos.startY)));
        setPosition({ x: newX, y: newY });
        setDragged(true); // Indicate that a drag occurred
    };

    // Handle mouse up or touch end
    const handleEnd = (e) => {
        setIsDragging(false);
    };

    return (
        <div
            className="mars-photo-gallery__space-container"
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleEnd}
            onMouseLeave={handleEnd} // Stops dragging if mouse leaves the div
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleEnd}
            style={{
                transform: `translateX(${position.x}px) translateY(${position.y}px)`,
                transition: "all linear 1000ms"
            }}
        >
            <div className={classNames('mars-photo-gallery__space', {

            })}>
                <img src={gridImg1} alt='' />
                <img src={gridImg2} alt='' />
                <img src={gridImg3} alt='' />
                <img src={gridImg4} alt='' />
                <img src={gridImg5} alt='' />
                <img src={gridImg6} alt='' />
                <img src={gridImg7} alt='' />
                <img src={gridImg8} alt='' />
                <img src={gridImg9} alt='' />
                <img src={gridImg10} alt='' />
                <img src={gridImg11} alt='' />
                <img src={gridImg12} alt='' />
                <img src={gridImg13} alt='' />
                <img src={gridImg14} alt='' />
                <img src={gridImg15} alt='' />
                <img src={gridImg16} alt='' />
                <img src={gridImg17} alt='' />
                <img src={gridImg18} alt='' />
                <img src={gridImg19} alt='' />
                <img src={gridImg20} alt='' />
                <img src={gridImg21} alt='' />
                <img src={gridImg22} alt='' />
                <img src={gridImg23} alt='' />
                <img src={gridImg24} alt='' />
                <img src={gridImg25} alt='' />
                <img src={gridImg26} alt='' />
                <img src={gridImg27} alt='' />
                <img src={gridImg28} alt='' />
                <img src={gridImg29} alt='' />
                <img src={gridImg30} alt='' />
                <img src={gridImg31} alt='' />
                <img src={gridImg32} alt='' />
                <img src={gridImg33} alt='' />
                <img src={gridImg34} alt='' />
                <img src={gridImg35} alt='' />
                <img src={gridImg36} alt='' />
                <img src={gridImg37} alt='' />
                <img src={gridImg38} alt='' />
                <img src={gridImg39} alt='' />
                <img src={gridImg40} alt='' />
                <img src={gridImg41} alt='' />
                <img src={gridImg42} alt='' />
                <img src={gridImg43} alt='' />
                <img src={gridImg44} alt='' />
                <img src={gridImg45} alt='' />
                <img src={gridImg46} alt='' />
                <img src={gridImg47} alt='' />
                <img src={gridImg48} alt='' />
                <img src={gridImg49} alt='' />
                <img src={gridImg50} alt='' />
                <img src={gridImg51} alt='' />
                <img src={gridImg52} alt='' />
                <img src={gridImg53} alt='' />
                <img src={gridImg54} alt='' />
                <img src={gridImg55} alt='' />
                <img src={gridImg56} alt='' />
                <img src={gridImg57} alt='' />
                <img src={gridImg58} alt='' />
                <img src={gridImg59} alt='' />
                <img src={gridImg60} alt='' />
                <img src={gridImg61} alt='' />
                <img src={gridImg62} alt='' />
                <img src={gridImg63} alt='' />
                <img src={gridImg64} alt='' />
                <img src={gridImg65} alt='' />
                <img src={gridImg66} alt='' />
                <img src={gridImg67} alt='' />
                <img src={gridImg68} alt='' />
                <img src={gridImg69} alt='' />
                <img src={gridImg70} alt='' />
                <img src={gridImg71} alt='' />
                <img src={gridImg72} alt='' />
                <img src={gridImg73} alt='' />
                <img src={gridImg74} alt='' />
                <img src={gridImg75} alt='' />
                <img src={gridImg76} alt='' />
                <img src={gridImg77} alt='' />
                <img src={gridImg78} alt='' />
                <img src={gridImg79} alt='' />
                <img src={gridImg80} alt='' />
                <img src={gridImg81} alt='' />
                <img src={gridImg82} alt='' />
                <img src={gridImg83} alt='' />
                <img src={gridImg84} alt='' />
                <img src={gridImg85} alt='' />
                <img src={gridImg86} alt='' />
                <img src={gridImg87} alt='' />
                <img src={gridImg88} alt='' />
                <img src={gridImg89} alt='' />
                <img src={gridImg90} alt='' />
                <img src={gridImg91} alt='' />
                <img src={gridImg92} alt='' />
                <img src={gridImg93} alt='' />
                <img src={gridImg94} alt='' />
                <img src={gridImg95} alt='' />
                <img src={gridImg96} alt='' />
                <img src={gridImg97} alt='' />
                <img src={gridImg98} alt='' />
                <img src={gridImg99} alt='' />
                <img src={gridImg100} alt='' />
                <img src={gridImg101} alt='' />
                <img src={gridImg102} alt='' />
                <img src={gridImg103} alt='' />
                <img src={gridImg104} alt='' />
                <img src={gridImg105} alt='' />
                <img src={gridImg106} alt='' />
                <img src={gridImg107} alt='' />
                <img src={gridImg108} alt='' />
                <img src={gridImg109} alt='' />
                <img src={gridImg110} alt='' />
                <img src={gridImg111} alt='' />
                <img src={gridImg112} alt='' />
                <img src={gridImg113} alt='' />
                <img src={gridImg114} alt='' />
                <img src={gridImg115} alt='' />
                <img src={gridImg116} alt='' />
                <img src={gridImg117} alt='' />
                <img src={gridImg118} alt='' />
                <img src={gridImg119} alt='' />
                <img src={gridImg120} alt='' />
                <img src={gridImg121} alt='' />
            </div>
            <div className='mars-photo-gallery__space-click-container'>
                {(
                    photoData.photos.map((e) => <div
                    key={`key-${e.id}`}
                    className={
                        classNames(
                            "mars-photo-gallery__space-click-cell",
                            `mars-photo-gallery__space-click-cell-${e.id}`
                        )
                    }
                    style={{
                        'left': e.x,
                        'top': e.y,
                        'width': e.width,
                        'height': e.height
                    }}
                    onClick={() => {
                        if (!dragged) {
                            console.log(e.id);
                            onImageClick(e);
                        }
                    }}
                    ></div>)
                )}
                {/* <div className='mars-photo-gallery__space-click-cell mars-photo-gallery__space-click-cell-8'></div> */}
            </div>
        </div>
    );
}

export default MarsPhotoGallery;