/* eslint-disable jsx-a11y/heading-has-content */
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import vision1Img from '../../img/moon-vision/vision-1.jpg';
import ScrollableViewWithFader from '../../components/scrollable-view-with-fader';

const ScrollContentRenderer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      { /* eslint-disable-next-line */}
      <a id="tab1" />
      <Trans
        i18nKey="moonVision.content1"
        components={[
          <h2 />,
          <p />,
          <h3 />,
          <p className={classNames(
            "moon-vision__margin-left",
            {
              "moon-vision__margin-left-en": (i18n.language === "en")
            }
           )} />
        ]}
      />
      <ol>
        <li>
          {t('moonVision.li1')}
        </li>
        <li>
          {t('moonVision.li2')}
        </li>
        <li>
          {t('moonVision.li3')}
        </li>
      </ol>
      <img src={vision1Img} alt='' />
      <div class="moon-vision__photo-thanks">{t('moonVision.photo-thanks')}</div>
      <p></p>
      <div className='moon-vision__scroll-content-divider'></div>
      { /* eslint-disable-next-line */}
      <a id="tab2"></a>
      <Trans
        i18nKey="moonVision.content2"
        components={[
          <h2 />,
          <p />,
          <h3 />
        ]}
      />
      <div className='moon-vision__scroll-content-divider'></div>
      { /* eslint-disable-next-line */}
      <a id="tab3"></a>
      <Trans
        i18nKey="moonVision.content3"
        components={[
          <h2 />,
          <p />,
          <h3 />
        ]}
      />
      <div className='moon-vision__scroll-content-padder'></div>
    </div>
  );
}

const MoonVision = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className='moon-global__right-deco showAnimation'></div>
      <div className="moon-vision showAnimation">
        <div className={
          classNames(
            "moon-vision__title",
            {
              "moon-vision__title-en": (i18n.language === 'en')
            }
          )
        } />
        <div className="moon-vision__text-box-container">
          <ScrollableViewWithFader anchorPoint={currentTab} className={
            classNames(
              "moon-vision__text-content",
              {
                "moon-vision__text-content-en": (i18n.language === 'en')
              }
            )
          }
            onScrollOverTag={(n) => {
              console.log(n);
              setActiveTab(n + 1);
            }}>
            <ScrollContentRenderer />
          </ScrollableViewWithFader>
        </div>
        <div className="moon-vision__tab-box-container">
          <div className={
            classNames(
              "moon-vision__tab-box-content",
              {
                "moon-vision__tab-box-content-en": (i18n.language === 'en')
              }
            )
          }>
            <div
              className={classNames({ 'active': activeTab === 1 })}
              onClick={() => setCurrentTab(1)}
              onTouchEnd={() => setCurrentTab(1)}
            >
              {t('moonVision.tab1')}
            </div>
            <div
              className={classNames({ 'active': activeTab === 2 })}
              onClick={() => setCurrentTab(2)}
              onTouchEnd={() => setCurrentTab(2)}
            >
              {t('moonVision.tab2')}
            </div>
            <div
              className={classNames({ 'active': activeTab === 3 })}
              onClick={() => setCurrentTab(3)}
              onTouchEnd={() => setCurrentTab(3)}
            >
              {t('moonVision.tab3')}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MoonVision;
