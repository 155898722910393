import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CeInlineButton from '../../components/ce-inline-button';
import ScrollableViewWithFader from '../../components/scrollable-view-with-fader';

import closeBtn from '../../img/mars-mission-intro/close-btn.png';
import classNames from 'classnames';
import { useLightbox } from '../../context/lightboxContext';

import marsImg1 from '../../img/mars-link-with-hk/img1.jpg'
import marsLightboxImg1 from '../../img/mars-link-with-hk/lightbox-img1.jpg';
import marsLightboxImg2 from '../../img/mars-link-with-hk/lightbox-img2.jpg';

const RenderLightBoxContent = ({index}) => {
	const { t, i18n } = useTranslation();

	switch (index) {
		case 1:
			return (
				<div className={
					classNames(
						"mars-link-with-hk__lightbox-box-container",
						{
							"mars-link-with-hk__lightbox-box-container-en": (i18n.language === 'en')
						}
					)}>
					<Trans
						i18nKey="marsLinkWithHK.tag1.content"
						components={[
							<img src={marsLightboxImg1} alt="" />,
							<></>,
							<div className="mars-link-with-hk__lightbox-title" />,
							<div className="mars-link-with-hk__lightbox-pic-source" />,
							<div className="mars-link-with-hk__lightbox-pic-desc" />,
							<p className="mars-link-with-hk__lightbox-p" />
						]} />
				</div>
			)
		case 2:
			return (
				<div className={
					classNames(
						"mars-link-with-hk__lightbox-box-container",
						{
							"mars-link-with-hk__lightbox-box-container-en": (i18n.language === 'en')
						}
					)}>
					<Trans
						i18nKey="marsLinkWithHK.tag2.content"
						components={[
							<img src={marsLightboxImg2} alt="" />,
							<></>,
							<div className="mars-link-with-hk__lightbox-title" />,
							<div className="mars-link-with-hk__lightbox-pic-source" />,
							<div className="mars-link-with-hk__lightbox-pic-desc" />,
							<p className="mars-link-with-hk__lightbox-p" />
						]} />
				</div>
			)
		case 3:
			return (
				<div className={
					classNames(
						"mars-link-with-hk__lightbox-box-container",
						{
							"mars-link-with-hk__lightbox-box-container-en": (i18n.language === 'en')
						}
					)}>
					<div className="mars-link-with-hk__lightbox-title">{ t('marsLinkWithHK.tag3.label') }</div>
					<img alt='' />
					<div>
						<span  className="mars-link-with-hk__lightbox-pic-source2">{t('marsLinkWithHK.tag3.content4')}</span>
						<span className="mars-link-with-hk__lightbox-pic-source">{t('marsLinkWithHK.tag3.content2')}</span>
					</div>
					<Trans
						i18nKey="marsLinkWithHK.tag3.content"
						components={[
							<img alt='' />,
							<img alt='' />,
							<div className="mars-link-with-hk__lightbox-title" />,
							<div className="mars-link-with-hk__lightbox-pic-source" />,
							<div className="mars-link-with-hk__lightbox-pic-desc" />,
							<p className="mars-link-with-hk__lightbox-p" />
						]} />
					<div>
						<span  className="mars-link-with-hk__lightbox-pic-source2">{t('marsLinkWithHK.tag3.content3')}</span>
						<span className="mars-link-with-hk__lightbox-pic-source">{t('marsLinkWithHK.tag3.content2')}</span>
					</div>
				</div>
			)
		default:
			return <></>;
	}
}

const MarsLinkWithHK = () => {
	const { i18n, t } = useTranslation();
	const [lightBoxIndex, setLightBoxIndex] = useState(null);
	const { enabledLightbox, disableLightbox } = useLightbox();
	
	return (
		<>
			<div className='mars-global__right-deco'></div>
			<div className="mars-link-with-hk">
				<div className="mars-link-with-hk__container showAnimation">
					<div className={i18n.language === 'en' ? "mars-link-with-hk__title-en" : "mars-link-with-hk__title"} />
				</div>
				<div className={classNames(
					"mars-link-with-hk__text-box-container", 
					"showAnimation",
					{
						"mars-link-with-hk__text-box-container-en": ( i18n.language === 'en' )
					}
					)}>
					<ScrollableViewWithFader className="mars-link-with-hk__text-content">
						<Trans
							i18nKey="marsLinkWithHK.content"
							components={[
								<p />,
								<p className="mars-link-with-hk__mini-title" />,
                				<div className="mars-link-with-hk__lightbox-pic-source" />,
                				<img src={marsImg1} alt='' />,
								<div className="mars-link-with-hk__credit" />
							]}
						/>
						<div className="mars-link-with-hk__tags">
							<CeInlineButton languageKey={t("marsLinkWithHK.tag1.label")} onClick={() => {
								enabledLightbox();
								setLightBoxIndex(1)
							}} />
							<CeInlineButton languageKey={t("marsLinkWithHK.tag2.label")} onClick={() => {
								enabledLightbox();
								setLightBoxIndex(2)
							}} />
						</div>
					</ScrollableViewWithFader>
				</div>
				{(lightBoxIndex)?
			(<div className={
				classNames("mars-link-with-hk__light-box-container showAnimation")}>
				<RenderLightBoxContent index={lightBoxIndex} />
				<img
					className='mars-link-with-hk__light-box-close-btn'
					src={closeBtn}
					onClick={(e) => {
						e.preventDefault();
						disableLightbox();
						setLightBoxIndex(null);
					}}
					onTouchEnd={(e) => {
						e.preventDefault();
						disableLightbox();
						setLightBoxIndex(null);
					}}
					alt=""
					/>
			</div>):<></>}
			</div>
			
		</>
	)
}

export default MarsLinkWithHK
