import { useState } from 'react';
import headingZhImg from '../../img/mars-menu/heading-zh.png';
import headingEnImg from '../../img/mars-menu/heading-en.png';
import { useTranslation } from 'react-i18next';
import { useTravel } from '../../context/travelContext';
import classNames from 'classnames';
import MoonMenuLottieButton from '../../components/moon-menu-lottie-button';
import { debounce } from 'lodash';
import Const from '../../const/const';

const MarsMenu = () => {
  const { i18n } = useTranslation();
  const { travelTo } = useTravel();
  const [ navigateToNext, setNavigateToNext ] = useState(false);

  const go = (where) => {
    setNavigateToNext(true);
    travelTo({value:where});
  }

  const dGo = debounce(go, Const.debounceValue);

  return (
    <>
      <div className={classNames("mars-menu__color-bar", "showAnimation", 
        {
          'fadeOut': navigateToNext
        }
      )}></div>
      <div className={classNames("mars-menu__mars-shadow","showAnimation",
        {
          'fadeOut': navigateToNext
        }
      )}></div>
      <div className="mars-menu">
        <div className={classNames("mars-menu__heading", "showAnimation", 
          {
            'fadeOut': navigateToNext
          }
        )}>
          <img src={(i18n.language === "zh")?headingZhImg:headingEnImg} alt='' />
        </div>
        <div className="mars-menu__mars">
          
        </div>
        <div className="mars-menu__items">
          <div className={classNames("mars-menu__item", "showAnimation", {
            'fadeOut': navigateToNext
          })}>
            <div onClick={() => {
                dGo('/mars-mission')
              }}
              onTouchEnd={() => {
                dGo('/mars-mission')
              }}>
                <MoonMenuLottieButton buttonLangKey={"marsMenu.marsMission"} />
            </div>
            <div onClick={() => {
                dGo('/mars-vision')
              }}
              onTouchEnd={() => {
                dGo('/mars-vision')
              }}>
                <MoonMenuLottieButton buttonLangKey={"marsMenu.marsVision"} />
            </div>
            <div onClick={() => {
                dGo('/mars-rover')
              }}
              onTouchEnd={() => {
                dGo('/mars-rover')
              }}>
                <MoonMenuLottieButton buttonLangKey={"marsMenu.marsRover"} />
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

export default MarsMenu
