import Lottie from 'react-lottie';
import * as btnLottie from '../lottie/moon-menu-button/MoonMissionMenu_BTN_bg.json';
import DivTsByLocale from './div-ts-by-locale';
import classNames from 'classnames';

const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: btnLottie,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const MoonMenuLottieButton = ({ className, buttonLangKey }) => {
    return (
        <div className={ classNames("moon-menu-lbtn__container", className)}>
            <Lottie 
                options={lottieOption}
                width={1020}
                height={235} />
            <DivTsByLocale languageKey={buttonLangKey} />
        </div>
    );
}

export default MoonMenuLottieButton;