import Lottie from 'react-lottie';
import * as btnLottie from '../lottie/moon-menu-button/MoonMissionMenu_BTN_bg.json';
import DivTsByLocale from './div-ts-by-locale';
import classNames from 'classnames';

const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: btnLottie,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const MoonMissionMenuLottieButton = ({ className, buttonLangKey }) => {

    return (
        <div className={ classNames("moon-mission-menu-lbtn__container", className) }>
            <Lottie 
                options={lottieOption}
                width={798}
                height={173} />
            <DivTsByLocale languageKey={buttonLangKey} />
        </div>
    );

}

export default MoonMissionMenuLottieButton;