import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import marsImg from '../../img/mars-mission/mars.png';
import marsEnImg from '../../img/mars-mission/mars-en.png';
import marsIntroImg from '../../img/mars-mission/mars_intro.png';
import marsIntroEnImg from '../../img/mars-mission/mars_intro_en.png';
import CE1Img from '../../img/mars-mission/CE1.png';
import CE2Img from '../../img/mars-mission/CE2.png';
import CE3Img from '../../img/mars-mission/CE3.png';
import CE4Img from '../../img/mars-mission/CE4.png';
import classNames from 'classnames';
import MoonMissionMenuLottieButton from '../../components/moon-mission-menu-lottie-button';
import { useTravel } from '../../context/travelContext';
import { debounce } from 'lodash';
import Const from '../../const/const';

const pageRouteName = '/mars-mission';

const MarsMission = () => {
  const { i18n } = useTranslation();
  const { travelValue, travelTo } = useTravel();
  const [navigateToNext, setNavigateToNext] = useState(false);

  const go = (value, state) => {
    travelTo({ value: value, state });
  }

  const dGo = debounce(go, Const.debounceValue);

  useEffect(() => {
    if (travelValue !== pageRouteName) {
      setNavigateToNext(true);
    } else {
      setNavigateToNext(false);
    }
  }, [travelValue]);

  return (
    <div className={
      classNames(
        "mars-mission",
        {
          "fadeOut": navigateToNext
        }
      )}>
      <div className={
        classNames(
          "mars-mission__title",
          "showAnimation",
          {
            "mars-mission__title-en": (i18n.language === "en")
          }
        )} />
      <div className={"mars-mission__shadow-under-button showAnimationWithDelay"}></div>
      <div className="mars-mission__mars showAnimationWithDelay">
        <img src={(i18n.language === 'en') ? marsEnImg : marsImg} alt='' />
      </div>
      <div className="mars-mission__mars-intro showAnimationWithDelay">
        <div onClick={() => dGo('/mars-mission-intro')}
          onTouchEnd={() => dGo('/mars-mission-intro')}>
          <img src={(i18n.language === 'en') ? marsIntroEnImg : marsIntroImg} alt='' />
        </div>

      </div>
      <div className="mars-mission__CE1">
        <div onClick={() => dGo('/mars-mission-intro', { year: 2007 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2007 })}>
          <img src={CE1Img} alt='' />
        </div>
      </div>
      <div className="mars-mission__CE2">
        <div onClick={() => dGo('/mars-mission-intro', { year: 2010 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2010 })}>
          <img src={CE2Img} alt='' />
        </div>
      </div>
      <div className="mars-mission__CE3">
        <div onClick={() => dGo('/mars-mission-intro', { year: 2014 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2014 })}>
          <img src={CE3Img} alt='' />
        </div>
      </div>
      <div className="mars-mission__CE4">
        <div onClick={() => dGo('/mars-mission-intro', { year: 2013 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2013 })}>
          <img src={CE4Img} alt='' />
        </div>
      </div>
      <div className="mars-mission__overlay-btns" >
        <div onClick={() => dGo('/mars-mission-intro', { year: 2007 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2007 })}>
          <div className="mars-mission__overlay-btns-ce1 mars-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/mars-mission-intro', { year: 2010 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2010 })}>
          <div className="mars-mission__overlay-btns-ce2 mars-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/mars-mission-intro', { year: 2014 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2014 })}>
          <div className="mars-mission__overlay-btns-ce3 mars-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/mars-mission-intro', { year: 2013 })}
          onTouchEnd={() => dGo('/mars-mission-intro', { year: 2013 })}>
          <div className="mars-mission__overlay-btns-ce4 mars-mission__overlay-btns-ce" />
        </div>
      </div>
      <div className="mars-mission__buttons showAnimationWithDelay">
        <div onClick={() => {
          dGo("/mars-photo-gallary");
        }}
          onTouchEnd={() => {
            dGo("/mars-photo-gallary");
          }}>
          <MoonMissionMenuLottieButton buttonLangKey={"marsMission.marsPhotoGallery"} />
        </div>
        <div onClick={() => {
          dGo("/mars-link-with-hk");
        }}
          onTouchEnd={() => {
            dGo("/mars-link-with-hk");
          }}>
          <MoonMissionMenuLottieButton buttonLangKey={"marsMission.marsLinkWithHK"} />
        </div>
      </div>
    </div>
  )
}

export default MarsMission
