import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Create the LightboxContext
const TravelContext = createContext();

export const useTravel = () => {
  return useContext(TravelContext);
};

export const TravelProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [travelValue, setTravelValue] = useState("/");
  // const [history, setHistory] = useState(['/']);

  
  useEffect(() => {
    if (location.pathname !== travelValue) {
      setTravelValue(location.pathname)
    };
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
      console.log("travel value", travelValue);
  }, [travelValue]);

  const travelTo = ({value, state, delay = 700}) => {
    // history.push(value);
    // console.log(history);
    setTravelValue(value);
    if (state) {
      setTimeout(() => navigate(value, {
        state
      }), delay);  
    } else {
      setTimeout(() => navigate(value), delay);
    }
  };
  
  const travelToPrev = (delay = 700) => {
    // if (history.length == 0) {
    //   setTimeout(() => navigate(-1), delay);
    //   return;
    // }
    // history.pop();
    // console.log("-1", history[history.length - 1]);
    // console.log("history", history);
    // setTravelValue(history[history.length - 1]);
    // setTimeout(() => navigate(-1), delay);
    // travelTo({value: history[history.length - 2], delay: 500});
    navigate(-1)
  }

  return (
    <TravelContext.Provider value={{ travelValue, travelTo, travelToPrev }}>
      {children}
    </TravelContext.Provider>
  );
};