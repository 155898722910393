import React, { createContext, useState, useContext } from 'react';

// Create the LightboxContext
const LightboxContext = createContext();

export const useLightbox = () => {
  return useContext(LightboxContext);
};

export const LightboxProvider = ({ children }) => {
  const [lightboxEnabled, setLightboxEnabled] = useState(false);

  const toggleLightbox = () => {
    setLightboxEnabled(prevState => !prevState);
  };

  const enabledLightbox = () => {
    setLightboxEnabled(true);
  }

  const disableLightbox = () => {
    setLightboxEnabled(false);
  }

  return (
    <LightboxContext.Provider value={{ lightboxEnabled, toggleLightbox, enabledLightbox, disableLightbox }}>
      {children}
    </LightboxContext.Provider>
  );
};