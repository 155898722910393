import moonImg from '../../img/moon-mission/moon.png';
import moonEnImg from '../../img/moon-mission/moon_en.png';
import moonIntroImg from '../../img/moon-mission/moon_intro.png';
import moonIntroEnImg from '../../img/moon-mission/moon_intro_en.png';
import CE1Img from '../../img/moon-mission/CE1.png';
import CE2Img from '../../img/moon-mission/CE2.png';
import CE3Img from '../../img/moon-mission/CE3.png';
import CE4Img from '../../img/moon-mission/CE4.png';
import CE5Img from '../../img/moon-mission/CE5.png';
import CE6Img from '../../img/moon-mission/CE6.png';
import MoonMissionMenuLottieButton from '../../components/moon-mission-menu-lottie-button';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useTravel } from '../../context/travelContext';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Const from '../../const/const';
const pageRouteName = '/moon-mission';

const MoonMission = () => {
  const { i18n } = useTranslation();

  const { travelValue, travelTo } = useTravel();
  const [ navigateToNext, setNavigateToNext ] = useState(false);

  const go = (where, state) => {
    travelTo({value:where, state});
  }

  const dGo = debounce(go, Const.debounceValue);

  useEffect(() => {
    if (travelValue !== pageRouteName) {
      setNavigateToNext(true);
    } else {
      setNavigateToNext(false);
    }
  }, [travelValue]);

  return (
    <div className={classNames("moon-mission", {
      "fadeOut": navigateToNext
    })}>
      <div className={"moon-mission__title showAnimation " + ((i18n.language === 'zh') ? 'moon-mission__show-with-block' : '')} />
      <div className={"moon-mission__title-en showAnimation " + ((i18n.language === 'en') ? 'moon-mission__show-with-block' : '')} />
      <div className={"moon-mission__shadow-under-button showAnimationWithDelay"}></div>
      <div className="moon-mission__moon showAnimationWithDelay">
        <img src={(i18n.language === 'en') ? moonEnImg:moonImg} alt='' />
      </div>
      <div className="moon-mission__moon-intro">
        <div onClick={() => dGo('/moon-mission-intro')}
           onTouchEnd={() => dGo('/moon-mission-intro')}>
          <img src={(i18n.language === 'en') ? moonIntroEnImg:moonIntroImg} alt='' />
        </div>
      </div>
      <div className="moon-mission__CE1">
        <div onClick={() => dGo('/moon-mission-intro', { year: 2007 })}
          onTouchEnd={() => dGo('/moon-mission-intro', { year: 2007 })}>
          <img src={CE1Img} alt='' />
        </div>
      </div>
      <div className="moon-mission__CE2">
        <div onClick={() => dGo('/moon-mission-intro', { year: 2010 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2010 })}>
          <img src={CE2Img} alt='' />
        </div>
      </div>
      <div className="moon-mission__CE3">
        <div onClick={() => dGo('/moon-mission-intro', { year: 2013 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2013 })}>
          <img src={CE3Img} alt='' />
        </div>
      </div>
      <div className="moon-mission__CE4">
        <div onClick={() => dGo('/moon-mission-intro', { year: 2014 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2014 })}>
          <img src={CE4Img} alt='' />
        </div>
      </div>
      <div className="moon-mission__CE5">
        <div onClick={() => dGo('/moon-mission-intro', { year: 2018 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2018 })}>
          <img src={CE5Img} alt='' />
        </div>
      </div>
      <div className="moon-mission__CE6">
        <div onClick={() => dGo('/moon-mission-intro', { year: 2020 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2020 })}>
          <img src={CE6Img} alt='' />
        </div>
      </div>
      <div className="moon-mission__overlay-btns" >
        <div onClick={() => dGo('/moon-mission-intro', { year: 2007 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2007 })}>
          <div  className="moon-mission__overlay-btns-ce1 moon-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/moon-mission-intro', { year: 2010 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2010 })}>
          <div  className="moon-mission__overlay-btns-ce2 moon-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/moon-mission-intro', { year: 2013 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2013 })}>
          <div  className="moon-mission__overlay-btns-ce3 moon-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/moon-mission-intro', { year: 2020 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2020 })}>
          <div  className="moon-mission__overlay-btns-ce6 moon-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/moon-mission-intro', { year: 2018 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2018 })}>
          <div  className="moon-mission__overlay-btns-ce5 moon-mission__overlay-btns-ce" />
        </div>
        <div onClick={() => dGo('/moon-mission-intro', { year: 2014 })}
           onTouchEnd={() => dGo('/moon-mission-intro', { year: 2014 })}>
          <div  className="moon-mission__overlay-btns-ce4 moon-mission__overlay-btns-ce" />
        </div>
      </div>
      <div className="moon-mission__buttons showAnimationWithDelay">
        <div onClick={() => dGo('/moon-photo-gallary')}
           onTouchEnd={() => dGo('/moon-photo-gallary')}>
          <MoonMissionMenuLottieButton buttonLangKey={'moonMission.moonPhotoGallery'} />
        </div>
        <div onClick={() => dGo('/moon-link-with-hk')}
           onTouchEnd={() => dGo('/moon-link-with-hk')}>
          <MoonMissionMenuLottieButton buttonLangKey={'moonMission.moonLinkWithHK'} />
        </div>
      </div>
    </div>
  )
}

export default MoonMission
