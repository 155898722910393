import landingBgMoonImg from './img/bg_moon.jpg';
import landingBgMarsImg from './img/bg_mars.jpg';
import landingDecoImg from './img/landing/Decoration_05BG.png';

import MarsLinkWithHKBGTitleZhImg from './img/mars-link-with-hk/title-zh.png';

import marsMenuBtnLinkImg from './img/mars-menu/btn_link.png';
import marsMenuHeadingZhImg from './img/mars-menu/heading-zh.png';
import marsMenuHeadingEnImg from './img/mars-menu/heading-en.png';

import marsMissionCE1Img from './img/mars-mission/CE1.png';
import marsMissionHeadingImg from './img/mars-mission/heading-zh.png';
import marsMissionHeadingEnImg from './img/mars-mission/heading-zh.png';
import marsMissionMarsIntroImg from './img/mars-mission/mars_intro.png';
import marsMissionMarsImg from './img/mars-mission/mars.png';

import marsMissionIntroHeadingImg from './img/mars-mission-intro/heading.png';
import marsMission1IntroHeadingImg from './img/mars-mission-intro/heading1.png';
import marsMission2IntroHeadingImg from './img/mars-mission-intro/heading2.png';
import marsMission3IntroHeadingImg from './img/mars-mission-intro/heading3.png';
import marsMission4IntroHeadingImg from './img/mars-mission-intro/heading4.png';
import marsMission1EnIntroHeadingImg from './img/mars-mission-intro/heading1-en.png';
import marsMission2EnIntroHeadingImg from './img/mars-mission-intro/heading2-en.png';
import marsMission3EnIntroHeadingImg from './img/mars-mission-intro/heading3-en.png';
import marsMission4EnIntroHeadingImg from './img/mars-mission-intro/heading4-en.png';
import marsMissionIntroMarsImg from './img/mars-mission-intro/mars.png';
import marsMissionIntroCE1Img from './img/mars-mission-intro/CE1.png';
import marsMissionIntroCloseBtnImg from './img/mars-mission-intro/close-btn.png';

import marsRoverFloatingPointImg from './img/mars-rover/floating-point.png';
import marsRoverSolarPanelImg from './img/mars-rover/solar-panel.jpg';
import marsRoverTitleZhImg from './img/mars-rover/title_zh.png';
import marsRoverTitleEnImg from './img/mars-rover/title_en.png';

import marsVisionHeadingImg from './img/mars-vision/heading.png';
import marsVisionTabBoxBackgroundImg from './img/mars-vision/tab_box_background.png';

import moonLinkWithHKImage1 from './img/moon-link-with-hk/image-1.jpg';
import MoonLinkWithHKTitleZh from './img/moon-link-with-hk/title-zh.png';

import moonMenuBtnLinkImg from './img/moon-menu/btn_link.png';
import moonMenuHeadingImg from './img/moon-menu/heading.png';
import moonMenuHeadingEnImg from './img/moon-menu/heading-en.png';
import moonMenuImg from './img/moon-menu/moon.png';

import moonMissionCE1Img from './img/moon-mission/CE1.png';
import moonMissionCE2Img from './img/moon-mission/CE2.png';
import moonMissionCE3Img from './img/moon-mission/CE3.png';
import moonMissionCE4Img from './img/moon-mission/CE4.png';
import moonMissionCE5Img from './img/moon-mission/CE5.png';
import moonMissionCE6Img from './img/moon-mission/CE6.png';
import moonMissionHeadingImg from './img/moon-mission/heading.png';
import moonMissionHeadingEnImg from './img/moon-mission/heading-en.png';
import moonMissionMoonIntroImg from './img/moon-mission/moon_intro.png';
import moonMissionMoonIntroEnImg from './img/moon-mission/moon_intro_en.png';
import moonMissionMoonImg from './img/moon-mission/moon.png';
import moonMissionMoonEnImg from './img/moon-mission/moon_en.png';

import moonMissionIntroHeadingImg from './img/moon-mission-intro/heading.png';
import moonMissionIntroHeading1Img from './img/moon-mission-intro/heading1.png';
import moonMissionIntroHeading2Img from './img/moon-mission-intro/heading2.png';
import moonMissionIntroHeading3Img from './img/moon-mission-intro/heading3.png';
import moonMissionIntroHeading4Img from './img/moon-mission-intro/heading4.png';
import moonMissionIntroHeading5Img from './img/moon-mission-intro/heading5.png';
import moonMissionIntroHeading6Img from './img/moon-mission-intro/heading6.png';
import moonMissionIntroHeadingEnImg from './img/moon-mission-intro/heading-en.png';
import moonMissionIntroHeading1EnImg from './img/moon-mission-intro/heading1-en.png';
import moonMissionIntroHeading2EnImg from './img/moon-mission-intro/heading2-en.png';
import moonMissionIntroHeading3EnImg from './img/moon-mission-intro/heading3-en.png';
import moonMissionIntroHeading4EnImg from './img/moon-mission-intro/heading4-en.png';
import moonMissionIntroHeading5EnImg from './img/moon-mission-intro/heading5-en.png';
import moonMissionIntroHeading6EnImg from './img/moon-mission-intro/heading6-en.png';
import moonMissionIntroMoonImg from './img/moon-mission-intro/moon.png';

import moonVisionHeadingImg from './img/moon-vision/heading.png';
import moonVisionHeadingEnImg from './img/moon-vision/heading-en.png';
import moonVisionTabBoxBackgroundImg from './img/moon-vision/tab_box_background.png';

import moonRoverTitleZhImg from './img/moon-rover/title_zh.png';
import moonRoverTitleEnImg from './img/moon-rover/title_en.png';

import moonPhotoGalleryTitleZhImg from './img/moon-photo-gallery/title_zh.png';
import moonPhotoGalleryTitleEnImg from './img/moon-photo-gallery/title_en.png';
import moonPhotoGalleryFilterImg from './img/moon-photo-gallery/filter.png';
import moonPhotoGalleryCloseBtnImg from './img/moon-photo-gallery/close-btn.png';
import moonPhotoGalleryImg01 from './img/moon-photo-gallery/image-01.jpg';

import img20071 from './img/moon-mission-intro/img-2007-1.jpg';
import img20072 from './img/moon-mission-intro/img-2007-2.jpg';
import img20101 from './img/moon-mission-intro/img-2010-1.jpg';
import img20102 from './img/moon-mission-intro/img-2010-2.jpg';
import img20131 from './img/moon-mission-intro/img-2013-1.jpg';
import img20132 from './img/moon-mission-intro/img-2013-2.jpg';
import img20141 from './img/moon-mission-intro/img-2014-1.jpg';
import img20142 from './img/moon-mission-intro/img-2014-2.jpg';
import img20181 from './img/moon-mission-intro/img-2018-1.jpg';
import img20182 from './img/moon-mission-intro/img-2018-2.jpg';
import img20183 from './img/moon-mission-intro/img-2018-3.jpg';
import img20201 from './img/moon-mission-intro/img-2020-1.jpg';
import img20202 from './img/moon-mission-intro/img-2020-2.jpg';

import gridImg2 from './img/moon-photo-gallery/grid/grid_2.jpg';
import gridImg3 from './img/moon-photo-gallery/grid/grid_3.jpg';
import gridImg4 from './img/moon-photo-gallery/grid/grid_4.jpg';
import gridImg12 from './img/moon-photo-gallery/grid/grid_12.jpg';
import gridImg13 from './img/moon-photo-gallery/grid/grid_13.jpg';
import gridImg14 from './img/moon-photo-gallery/grid/grid_14.jpg';
import gridImg22 from './img/moon-photo-gallery/grid/grid_22.jpg';
import gridImg23 from './img/moon-photo-gallery/grid/grid_23.jpg';
import gridImg24 from './img/moon-photo-gallery/grid/grid_24.jpg';
import gridImg32 from './img/moon-photo-gallery/grid/grid_32.jpg';
import gridImg33 from './img/moon-photo-gallery/grid/grid_33.jpg';
import gridImg34 from './img/moon-photo-gallery/grid/grid_34.jpg';
import gridImg42 from './img/moon-photo-gallery/grid/grid_42.jpg';
import gridImg43 from './img/moon-photo-gallery/grid/grid_43.jpg';
import gridImg44 from './img/moon-photo-gallery/grid/grid_44.jpg';

import marsVisionImg1 from './img/mars-vision/img1.jpg';
import marsVisionImg2 from './img/mars-vision/img2.jpg';

const PreloadImages = () => {
  return (
    <>
      <link rel="preload" as="image" href={landingBgMoonImg} />
      <link rel="preload" as="image" href={landingBgMarsImg} />
      <link rel="preload" as="image" href={landingDecoImg} />
      <link rel="preload" as="image" href={MarsLinkWithHKBGTitleZhImg} />

      <link rel="preload" as="image" href={marsMenuBtnLinkImg} />
      <link rel="preload" as="image" href={marsMenuHeadingEnImg} />
      <link rel="preload" as="image" href={marsMenuHeadingZhImg} />

      <link rel="preload" as="image" href={marsMissionCE1Img} />
      <link rel="preload" as="image" href={marsMissionHeadingImg} />
      <link rel="preload" as="image" href={marsMissionHeadingEnImg} />
      <link rel="preload" as="image" href={marsMissionMarsIntroImg} />
      <link rel="preload" as="image" href={marsMissionMarsImg} />

      <link rel="preload" as="image" href={marsMissionIntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission1IntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission2IntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission3IntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission4IntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission1EnIntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission2EnIntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission3EnIntroHeadingImg} />
      <link rel="preload" as="image" href={marsMission4EnIntroHeadingImg} />
      <link rel="preload" as="image" href={marsMissionIntroMarsImg} />
      <link rel="preload" as="image" href={marsMissionIntroCE1Img} />
      <link rel="preload" as="image" href={marsMissionIntroCloseBtnImg} />

      <link rel="preload" as="image" href={marsRoverFloatingPointImg} />
      <link rel="preload" as="image" href={marsRoverSolarPanelImg} />
      <link rel="preload" as="image" href={marsRoverTitleZhImg} />
      <link rel="preload" as="image" href={marsRoverTitleEnImg} />

      <link rel="preload" as="image" href={marsVisionHeadingImg} />
      <link rel="preload" as="image" href={marsVisionTabBoxBackgroundImg} />

      <link rel="preload" as="image" href={moonLinkWithHKImage1} />
      <link rel="preload" as="image" href={MoonLinkWithHKTitleZh} />

      <link rel="preload" as="image" href={moonMenuBtnLinkImg} />
      <link rel="preload" as="image" href={moonMenuHeadingImg} />
      <link rel="preload" as="image" href={moonMenuHeadingEnImg} />
      <link rel="preload" as="image" href={moonMenuImg} />

      <link rel="preload" as="image" href={moonMissionCE1Img} />
      <link rel="preload" as="image" href={moonMissionCE2Img} />
      <link rel="preload" as="image" href={moonMissionCE3Img} />
      <link rel="preload" as="image" href={moonMissionCE4Img} />
      <link rel="preload" as="image" href={moonMissionCE5Img} />
      <link rel="preload" as="image" href={moonMissionCE6Img} />
      <link rel="preload" as="image" href={moonMissionHeadingImg} />
      <link rel="preload" as="image" href={moonMissionHeadingEnImg} />
      <link rel="preload" as="image" href={moonMissionMoonIntroImg} />
      <link rel="preload" as="image" href={moonMissionMoonIntroEnImg} />
      <link rel="preload" as="image" href={moonMissionMoonImg} />
      <link rel="preload" as="image" href={moonMissionMoonEnImg} />

      <link rel="preload" as="image" href={moonMissionIntroHeadingImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading1Img} />
      <link rel="preload" as="image" href={moonMissionIntroHeading2Img} />
      <link rel="preload" as="image" href={moonMissionIntroHeading3Img} />
      <link rel="preload" as="image" href={moonMissionIntroHeading4Img} />
      <link rel="preload" as="image" href={moonMissionIntroHeading5Img} />
      <link rel="preload" as="image" href={moonMissionIntroHeading6Img} />
      <link rel="preload" as="image" href={moonMissionIntroHeadingEnImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading1EnImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading2EnImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading3EnImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading4EnImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading5EnImg} />
      <link rel="preload" as="image" href={moonMissionIntroHeading6EnImg} />
      <link rel="preload" as="image" href={moonMissionIntroMoonImg} />

      <link rel="preload" as="image" href={moonVisionHeadingImg} />
      <link rel="preload" as="image" href={moonVisionHeadingEnImg} />
      <link rel="preload" as="image" href={moonVisionTabBoxBackgroundImg} />

      <link rel="preload" as="image" href={moonRoverTitleZhImg} />
      <link rel="preload" as="image" href={moonRoverTitleEnImg} />

      <link rel="preload" as="image" href={moonPhotoGalleryTitleZhImg} />
      <link rel="preload" as="image" href={moonPhotoGalleryTitleEnImg} />
      <link rel="preload" as="image" href={moonPhotoGalleryFilterImg} />
      <link rel="preload" as="image" href={moonPhotoGalleryCloseBtnImg} />
      <link rel="preload" as="image" href={moonPhotoGalleryImg01} />


      <link rel="preload" as="image" href={img20071} />
      <link rel="preload" as="image" href={img20072} />
      <link rel="preload" as="image" href={img20101} />
      <link rel="preload" as="image" href={img20102} />
      <link rel="preload" as="image" href={img20131} />
      <link rel="preload" as="image" href={img20132} />
      <link rel="preload" as="image" href={img20141} />
      <link rel="preload" as="image" href={img20142} />
      <link rel="preload" as="image" href={img20181} />
      <link rel="preload" as="image" href={img20182} />
      <link rel="preload" as="image" href={img20183} />
      <link rel="preload" as="image" href={img20201} />
      <link rel="preload" as="image" href={img20202} />

      <link rel="preload" as="image" href={gridImg2} />
      <link rel="preload" as="image" href={gridImg3} />
      <link rel="preload" as="image" href={gridImg4} />
      <link rel="preload" as="image" href={gridImg12} />
      <link rel="preload" as="image" href={gridImg13} />
      <link rel="preload" as="image" href={gridImg14} />
      <link rel="preload" as="image" href={gridImg22} />
      <link rel="preload" as="image" href={gridImg23} />
      <link rel="preload" as="image" href={gridImg24} />
      <link rel="preload" as="image" href={gridImg32} />
      <link rel="preload" as="image" href={gridImg33} />
      <link rel="preload" as="image" href={gridImg34} />
      <link rel="preload" as="image" href={gridImg42} />
      <link rel="preload" as="image" href={gridImg43} />
      <link rel="preload" as="image" href={gridImg44} />

      <link rel="preload" as="image" href={marsVisionImg1} />
      <link rel="preload" as="image" href={marsVisionImg2} />

    </>
  );
};

export default PreloadImages;
