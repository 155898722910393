import { useEffect } from 'react';

export const useIdleTimer = (timeout, onIdle) => {
  useEffect(() => {
    let timer;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(onIdle, timeout);
    };

    window.addEventListener('mousedown', resetTimer);

    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousedown', resetTimer);
    };
  }, [timeout, onIdle]);
}
