import React, { useState, useRef, useEffect } from 'react';

import seekbarImg1 from '../img/cseekbar/ScrollBar_0.png';
import seekbarImg2 from '../img/cseekbar/ScrollBar_1.png';
import seekbarImg3 from '../img/cseekbar/ScrollBar_2.png';
import seekbarImg4 from '../img/cseekbar/ScrollBar_3.png';
import seekbarImg5 from '../img/cseekbar/ScrollBar_4.png';
import seekbarImg6 from '../img/cseekbar/ScrollBar_5.png';
import seekbarImg7 from '../img/cseekbar/ScrollBar_6.png';
import seekbarImg8 from '../img/cseekbar/ScrollBar_7.png';
import seekbarImg9 from '../img/cseekbar/ScrollBar_8.png';
import seekbarImg10 from '../img/cseekbar/ScrollBar_9.png';
import seekbarImg11 from '../img/cseekbar/ScrollBar_10.png';
import seekbarImg12 from '../img/cseekbar/ScrollBar_11.png';
import seekbarImg13 from '../img/cseekbar/ScrollBar_12.png';
import seekbarImg14 from '../img/cseekbar/ScrollBar_13.png';
import seekbarImg15 from '../img/cseekbar/ScrollBar_14.png';
import seekbarImg16 from '../img/cseekbar/ScrollBar_15.png';
import seekbarImg17 from '../img/cseekbar/ScrollBar_16.png';
import seekbarImg18 from '../img/cseekbar/ScrollBar_17.png';
import seekbarImg19 from '../img/cseekbar/ScrollBar_18.png';
import seekbarImg20 from '../img/cseekbar/ScrollBar_19.png';
import seekbarImg21 from '../img/cseekbar/ScrollBar_20.png';
import seekbarImg22 from '../img/cseekbar/ScrollBar_21.png';
import seekbarImg23 from '../img/cseekbar/ScrollBar_22.png';
import seekbarImg24 from '../img/cseekbar/ScrollBar_23.png';
import seekbarImg25 from '../img/cseekbar/ScrollBar_24.png';
import seekbarImg26 from '../img/cseekbar/ScrollBar_25.png';
import seekbarImg27 from '../img/cseekbar/ScrollBar_26.png';
import seekbarImg28 from '../img/cseekbar/ScrollBar_27.png';
import seekbarImg29 from '../img/cseekbar/ScrollBar_28.png';
import seekbarImg30 from '../img/cseekbar/ScrollBar_29.png';
import seekbarImg31 from '../img/cseekbar/ScrollBar_30.png';
import seekbarImg32 from '../img/cseekbar/ScrollBar_31.png';
import seekbarImg33 from '../img/cseekbar/ScrollBar_32.png';
import seekbarImg34 from '../img/cseekbar/ScrollBar_33.png';
import seekbarImg35 from '../img/cseekbar/ScrollBar_34.png';
import seekbarImg36 from '../img/cseekbar/ScrollBar_35.png';

const seekBarImages = [
    seekbarImg1,seekbarImg2,seekbarImg3,seekbarImg4,seekbarImg5,seekbarImg6,seekbarImg7,seekbarImg8,seekbarImg9,seekbarImg10,seekbarImg11,seekbarImg12,seekbarImg13,seekbarImg14,seekbarImg15,seekbarImg16,seekbarImg17,seekbarImg18,seekbarImg19,seekbarImg20,seekbarImg21,seekbarImg22,seekbarImg23,seekbarImg24,seekbarImg25,seekbarImg26,seekbarImg27,seekbarImg28,seekbarImg29,seekbarImg30,seekbarImg31,seekbarImg32,seekbarImg33,seekbarImg34,seekbarImg35,seekbarImg36,
];

const CircularSeekBar = ({ min = 0, max = 35, value = 0, onChange, onSettled }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    const barRef = useRef(null);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        updateValue(e);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        updateValue(e);
    }

    const handleMouseMove = (e) => {
        if (isDragging) {
            updateValue(e);
        }
    };

    const handleTouchMove = (e) => {
        if (isDragging) {
            updateValue(e);
        }
    }

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false);
            onSettled(currentValue);
        }
    };

    const handleTouchEnd = () => {
        if (isDragging) {
            setIsDragging(false);
            onSettled(currentValue);
        }
    }

    const updateValue = (e) => {
        const rect = barRef.current.getBoundingClientRect();
        var offsetX = 0;
        if (e.touches) {
            offsetX = e.touches[0].clientX - rect.left;
        } else {
            offsetX = e.clientX - rect.left
        }
        let newValue = Math.max(min, Math.min(max, ((offsetX / rect.width) * (max - min)) + min));
        newValue = newValue.toFixed(0);
        setCurrentValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove );
            window.removeEventListener('mouseup', handleTouchEnd );


            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleMouseUp);

        };
        // eslint-disable-next-line
    }, [isDragging]);
    return (
        <div
            className="cseekbar__seek-bar"
            ref={barRef}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            // style={{
            //     'backgroundImage': `url('${process.env.PUBLIC_URL}/cseekbar/ScrollBar_${(currentValue+1).toFixed(0)}.png')`
            // }}
        >
            <img src={seekBarImages[currentValue]} alt="" />
            <div
                className="cseekbar__seek-bar-thumb"
                style={{ left: `${((currentValue - min) / (max - min)) * 100}%` }}
            ><img draggable="false" alt="" /></div>
        </div>
    );
};

export default CircularSeekBar;
